import { GradientBorder } from "./GradientContainer";

const defaultProfileImage =
	"https://preview.redd.it/new-profile-pictures-for-yall-v0-brdjms2xte3c1.jpg?width=720&format=pjpg&auto=webp&s=ee4dd7a6b958c218987219c7ba5311424d2a3345";

export function UserProfileComponent({
	profile,
	winRatio,
}: Readonly<{
	profile: {
		profileImage?: string | null;
		gamertag?: string | null;
	};
	winRatio?: number;
}>) {
	return (
		<>
			<div className="w-full flex justify-center">
				<div className="relative flex items-start space-x-2">
					<div className="w-32 h-32 rounded-lg overflow-hidden">
						<GradientBorder padding="p-0.5">
							<img alt="" src={profile.profileImage ?? defaultProfileImage} />
						</GradientBorder>
					</div>
				</div>
			</div>

			<div className="w-full flex flex-col items-center justify-center space-y-2">
				{profile.gamertag && (
					<p className="text-lg font-thin text-white">{profile.gamertag}</p>
				)}
				<div className="flex justify-center w-full">
					<div className="bg-gray-500/30 backdrop-blur-lg rounded-lg flex space-x-1 items-center py-1 px-2">
						<div className="size-4">
							<img src="/icons/trophy.webp" alt="" className="size-4" />
						</div>
						<p className="font-semibold text-white">0</p>
					</div>
				</div>
				{typeof winRatio === "number" && (
					<div className="flex items-center text-lg space-x-1 bg-gray-500/20 backdrop-blur-lg py-1 px-2 rounded-lg">
						<p className="text-white ">Win Ratio:</p>
						<p className="font-bold px-1 text-white">
							{winRatio}% {winRatio > 70 ? "🥇" : winRatio < 30 ? "😢" : "💪"}
						</p>
					</div>
				)}
			</div>
		</>
	);
}
