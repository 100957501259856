import type { Game } from "../../types";
import { Get } from "../fetch.wrapper";
import { routes } from "../routes";
import type { GameResponse } from "../types";

export default async function GetGames(): Promise<Game[]> {
	const data = await Get<GameResponse>(routes.games());

	return data.map(
		(d) =>
			({
				id: d.id,
				name: d.name,
				description: d.description,
				gameplayPreview: d.preview_url,
				gamePreview: d.banner_url,
				icon: d.icon_url,
			}) as Game,
	);
}
