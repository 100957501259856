import type { ComponentPropsWithRef } from "react";
import GradientContainer from "./GradientContainer";
import { cn } from "src/utils/cn";

export const GradientButton = ({
	className,
	children,
	...rest
}: ComponentPropsWithRef<"button">) => {
	return (
		<GradientContainer styles="rounded-2xl w-full text-center shadow-glow">
			<button
				type="button"
				className={cn(
					"text-white hover:text-purple-200 font-bold py-3 px-6",
					className,
				)}
				{...rest}
			>
				{children}
			</button>
		</GradientContainer>
	);
};
