import type { UserAccount } from "src/api/types";
import { Get } from "../../fetch.wrapper";
import { routes } from "../../routes";
import type { Account } from "src/types";

export default async function GetAccount(): Promise<Account> {
	const data = await Get<UserAccount>(routes.me());
	return {
		email: data.email_address,
		gamerTag: data.gamer_tag,
		id: data.id,
		nickName: data.nickname ?? "",
		profileImage: data.profile_image,
		chatToken: data.chat_token ?? "",
		country: {
			code: "Netherlands",
			name: "NL",
		},
		soundOn: true,
	};
}
