import type { ComponentPropsWithoutRef } from "react";
import { cn } from "src/utils/cn";

export const Field = ({
	label,
	className,
	error,
	...rest
}: ComponentPropsWithoutRef<"input"> & {
	label: string;
	error?: string | null;
}) => {
	return (
		<div className={cn("py-2", className)}>
			<label
				htmlFor={rest.id}
				className="text-gray-400 text-sm pb-1 block font-medium"
			>
				{label}
			</label>
			<input
				type="text"
				className={
					"w-full min-h-[52px] focus:outline-hairline  rounded-2xl px-4 py-2 border-hairline bg-[#2a2d34] placeholder:text-[#7d7f83] text-white"
				}
				{...rest}
			/>
			{error && (
				<p
					role="alert"
					className="text-gray-400 text-sm flex items-center gap-x-1 mt-1 font-medium"
				>
					<svg
						role="presentation"
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M8.00001 14.6654C11.6819 14.6654 14.6667 11.6806 14.6667 7.9987C14.6667 4.3168 11.6819 1.33203 8.00001 1.33203C4.31811 1.33203 1.33334 4.3168 1.33334 7.9987C1.33334 11.6806 4.31811 14.6654 8.00001 14.6654ZM9.76777 7.0998C9.96303 6.90454 9.96303 6.58795 9.76777 6.39269C9.5725 6.19743 9.25592 6.19743 9.06066 6.39269L8 7.45335L6.93934 6.39269C6.74408 6.19743 6.42749 6.19743 6.23223 6.39269C6.03697 6.58795 6.03697 6.90454 6.23223 7.0998L7.29289 8.16046L6.23223 9.22112C6.03697 9.41638 6.03697 9.73296 6.23223 9.92823C6.42749 10.1235 6.74408 10.1235 6.93934 9.92823L8 8.86757L9.06066 9.92823C9.25592 10.1235 9.5725 10.1235 9.76777 9.92823C9.96303 9.73296 9.96303 9.41638 9.76777 9.22112L8.70711 8.16046L9.76777 7.0998Z"
							fill="url(#paint0_linear_800_2945)"
						/>
						<defs>
							<linearGradient
								id="paint0_linear_800_2945"
								x1="-5.33332"
								y1="7.9987"
								x2="8.00001"
								y2="21.332"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#FF5E00" />
								<stop offset="0.541068" stopColor="#F54562" />
								<stop offset="1" stopColor="#C32DE1" />
							</linearGradient>
						</defs>
					</svg>

					{error}
				</p>
			)}
		</div>
	);
};
