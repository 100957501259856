import { useQuery, useQueryClient } from "@tanstack/react-query";
import { createContext, useContext, ReactNode, useMemo } from "react";
import { Party } from "../types";
import GetPublicParties from "../api/party/getPublicParties";
import GetPrivateParties from "../api/party/getPrivateParties";
import { useAccount } from "./accountProvider";

// Create a context with undefined initial value
const PartiesContext = createContext<
	| {
			isPartiesLoading: boolean;
			publicParties: Party[];
			privateParties: Party[];
			refreshParties: () => void;
	  }
	| undefined
>(undefined);

// Create the provider component
export function PartiesProvider({
	children,
}: Readonly<{
	children: ReactNode;
}>) {
	const { account } = useAccount();
	const qClient = useQueryClient();

	const publicPartiesQueryKey = useMemo(
		() => ["publicParties", account?.id],
		[account],
	);

	const privatePartiesQueryKey = useMemo(
		() => ["privateParties", account?.id],
		[account],
	);

	const publicPartiesQuery = useQuery({
		queryKey: publicPartiesQueryKey,
		queryFn: async () => await GetPublicParties(),
	});

	const privatePartiesQuery = useQuery({
		queryKey: privatePartiesQueryKey,
		queryFn: async () => await GetPrivateParties(),
	});

	const props = useMemo(
		() => ({
			isPartiesLoading:
				publicPartiesQuery.isLoading || privatePartiesQuery.isLoading,
			privateParties: privatePartiesQuery.data ?? [],
			publicParties: publicPartiesQuery.data ?? [],
			refreshParties: () => {
				qClient.refetchQueries({
					queryKey: publicPartiesQueryKey,
				});
				qClient.refetchQueries({
					queryKey: privatePartiesQueryKey,
				});
			},
		}),
		[
			publicPartiesQuery.isLoading,
			publicPartiesQuery.data,
			privatePartiesQuery.isLoading,
			privatePartiesQuery.data,
			qClient,
			publicPartiesQueryKey,
			privatePartiesQueryKey,
		],
	);

	return (
		<PartiesContext.Provider value={props}>{children}</PartiesContext.Provider>
	);
}

export function useParties() {
	const context = useContext(PartiesContext);
	if (context === undefined) {
		throw new Error("useParties must be used within a PartiesProvider");
	}
	return context;
}
