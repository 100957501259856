import { useState } from "react";
import FollowUser from "../api/account/follow/followUser";
import UnfollowUser from "../api/account/follow/unfollowUser";

export default function useFollowing() {
	const [isFollowingLoading, setIsFollowingLoading] = useState(false);
	const [isUnfollowingloading, setIsUnfollowingloading] = useState(false);

	async function followUser(id: string) {
		setIsFollowingLoading(true);
		await FollowUser(id).finally(() => setIsFollowingLoading(false));
	}

	async function unfollowUser(id: string) {
		setIsUnfollowingloading(true);
		await UnfollowUser(id).finally(() => setIsUnfollowingloading(false));
	}

	return {
		follow: followUser,
		unfollow: unfollowUser,
		isFollowingLoading,
		isUnfollowingloading,
	};
}
