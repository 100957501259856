import { Dialog } from "@headlessui/react";
import { GradientBorder } from "./GradientContainer";

export const UnfriendModal = ({
	isOpen,
	name,
	onClose,
	onConfirm,
}: {
	isOpen: boolean;
	name: string;
	onClose: () => void;
	onConfirm?: () => void;
}) => {
	return (
		<Dialog open={isOpen} onClose={() => {}} className="relative z-50">
			<div
				className="fixed inset-0 bg-black bg-opacity-30"
				aria-hidden="true"
			/>
			<div className="fixed inset-0 flex items-center justify-center p-4">
				<Dialog.Panel className="bg-tic-taps-grey rounded-2xl border border-tic-taps-grey shadow-2xl p-6 w-full max-w-md">
					<Dialog.Title className="text-lg font-semibold mb-4 text-white text-center">
						Unfriend user?
					</Dialog.Title>
					<Dialog.Description className="mb-6 text-white text-center">
						To unfriend{" "}
						<span className="bg-gradient-to-r from-[#FF5E00] to-[#FFAA00] text-transparent bg-clip-text">
							{name}
						</span>
						, please click the button below.
					</Dialog.Description>
					<GradientBorder
						padding="p-0.5"
						from="#FF5E00"
						to="#FFAA00"
						via={["#FFAA00"]}
					>
						<button
							type="button"
							onClick={() => {
								if (onConfirm) onConfirm();
							}}
							className="px-5 py-2 w-full rounded-lg transition-colors bg-tic-taps-grey text-white hover:bg-gray-900 hover:shadow-glow"
						>
							Unfriend
						</button>
					</GradientBorder>
					<button
						type="button"
						onClick={onClose}
						className="mt-4 text-gray-400 underline w-full text-center"
					>
						Close
					</button>
				</Dialog.Panel>
			</div>
		</Dialog>
	);
};
