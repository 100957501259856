import { useEffect } from "react";
import { useAuth } from "../../providers/authProvider";

export function LogoutPage() {
	const { logout } = useAuth();
	useEffect(() => {
		logout();
	}, []);
	return <></>;
}
