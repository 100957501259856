import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { Sheet } from "react-modal-sheet";
import GradientContainer from "src/components/GradientContainer";
import { Logo } from "src/website/Logo";

const faqs = [
	[
		{
			question: "How do I know the games are reliable?",
			answer:
				"We prioritize quality and reliability in our games because your success is our success. Our community of players has consistently shared positive experiences that reflect the effectiveness of our insights. Just give it a try—you won’t be disappointed!",
		},
		{
			question: "Can I really make money through gaming?",
			answer:
				"Absolutely! Tictaps combines gaming with cryptocurrency, allowing you to earn real rewards for your gameplay. The more you play and engage with the community, the more opportunities you have to boost your earnings.",
		},
	],
];

interface AboutUsSheetProps {
	open: boolean;
	onClose: () => void;
}

const AboutUsSheet = ({ open, onClose }: AboutUsSheetProps) => {
	const [openFaq, setOpenFaq] = useState<number | null>(null);

	const toggleFaq = (index: number) => {
		setOpenFaq(openFaq === index ? null : index);
	};
	return (
		<Sheet isOpen={open} onClose={onClose}>
			<Sheet.Container className="bg-custom-zinc max-h-[700px]">
				<Sheet.Header className="text-white text-center font-bold text-xl p-2 z-10 flex items-center justify-center relative">
					<span className="flex-1">About</span>
					<ChevronDownIcon className="h-5 w-5 absolute right-2" />
				</Sheet.Header>

				<Sheet.Content className="relative flex flex-col items-center p-4 overflow-hidden z-10 flex-grow">
					<div className="w-full flex flex-col items-center space-y-6 text-white">
						<div className="w-full flex items-center justify-center my-4 bg-gradient-to-br from-tic-taps-grey to-zinc-800 border-hairline shadow-xl border-zinc-700 p-4 rounded-2xl">
							<Logo className="w-12 h-12 mr-3" />{" "}
							<div className="text-center ml-4">
								<h2 className="text-xl font-semibold">Tictaps</h2>
								<p className="text-sm text-gray-400">Version 1.0.0</p>
							</div>
						</div>

						<h3 className="text-white text-lg font-semibold">
							Frequently Asked Questions
						</h3>
						{faqs.map((faqGroup, groupIndex) => (
							<div key={groupIndex} className="space-y-4 w-full">
								{faqGroup.map((faq, idx) => (
									<div
										key={idx}
										className="bg-gradient-to-br border-hairline border-zinc-600 shadow-xl from-tic-taps-grey to-zinc-800 p-4 rounded-2xl"
									>
										<button
											type="button"
											onClick={() => toggleFaq(groupIndex * 10 + idx)}
											className="cursor-pointer flex items-center justify-between text-left"
										>
											<p className="font-bold text-gray-100">{faq.question}</p>
											<ChevronDownIcon
												className={`h-5 w-5 text-gray-100 transform transition-transform duration-200 ${
													openFaq === groupIndex * 10 + idx ? "rotate-180" : ""
												}`}
											/>
										</button>
										{openFaq === groupIndex * 10 + idx && (
											<p className="text-gray-400 mt-2">{faq.answer}</p>
										)}
									</div>
								))}
							</div>
						))}
					</div>

					<div className="flex flex-col items-center mt-auto mb-6 w-full">
						<GradientContainer styles="rounded-2xl w-full text-center shadow-glow">
							<button
								type="button"
								onClick={onClose}
								className="text-white hover:text-purple-200 font-bold py-3 px-6"
							>
								Close
							</button>
						</GradientContainer>
					</div>
				</Sheet.Content>
			</Sheet.Container>
			<Sheet.Backdrop />
		</Sheet>
	);
};

export default AboutUsSheet;
