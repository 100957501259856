import { useMemo, useState } from "react";
import BubblesBackground from "src/website/BubblesBackground";
import { useNoBackground } from "src/providers/layoutProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faHeartBroken,
	faSearch,
	faUserSlash,
	faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { GradientBorder } from "src/components/GradientContainer";
import { LoadingOverlay } from "src/components/LoadingOverlay";
import { useFriends } from "src/hooks/useFriends";
import { useUserSearch } from "src/hooks/useUserSearch";
import type { Friend, UserAccount } from "src/api/types";
import { UnfriendModal } from "src/components/UnfriendModal";
import { APIError } from "src/components/APIError";
import { useNavigate } from "react-router-dom";
import { ttRoutes } from "src/hooks/useTTRouter";
import { Dialog } from "@headlessui/react";

const RequestFriendModal = ({
	isOpen,
	name,
	onClose,
	onConfirm,
	isLoading,
}: {
	isOpen: boolean;
	name: string;
	onClose: () => void;
	onConfirm?: () => void;
	isLoading?: boolean;
}) => {
	return (
		<Dialog open={isOpen} onClose={() => {}} className="relative z-50">
			<div
				className="fixed inset-0 bg-black bg-opacity-30"
				aria-hidden="true"
			/>
			<div className="fixed inset-0 flex items-center justify-center p-4">
				<Dialog.Panel className="bg-tic-taps-grey rounded-2xl border border-tic-taps-grey shadow-2xl p-6 w-full max-w-md">
					<Dialog.Title className="text-lg font-semibold mb-4 text-white text-center">
						Send Friend Request?
					</Dialog.Title>
					<Dialog.Description className="mb-6 text-white text-center">
						Do you want to send a friend request to{" "}
						<span className="bg-gradient-to-r from-[#FF5E00] to-[#FFAA00] text-transparent bg-clip-text">
							{name}
						</span>
						?
					</Dialog.Description>
					<GradientBorder
						padding="p-0.5"
						from="#FF5E00"
						to="#FFAA00"
						via={["#FFAA00"]}
					>
						<button
							type="button"
							disabled={isLoading}
							onClick={() => {
								if (onConfirm) onConfirm();
							}}
							className={`px-5 py-2 w-full rounded-lg transition-colors bg-tic-taps-grey text-white ${
								isLoading ? "opacity-75 cursor-not-allowed" : ""
							}`}
						>
							{isLoading ? "Sending Request..." : "Send Request"}
						</button>
					</GradientBorder>
					<button
						type="button"
						onClick={onClose}
						disabled={isLoading}
						className={`mt-4 text-gray-400 underline w-full text-center ${
							isLoading ? "opacity-50 cursor-not-allowed" : ""
						}`}
					>
						Cancel
					</button>
				</Dialog.Panel>
			</div>
		</Dialog>
	);
};

export function FriendsPage() {
	useNoBackground();
	const navigate = useNavigate();
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedFriend, setSelectedFriend] = useState<Friend | null>(null);
	const [showUnfriendModal, setShowUnfriendModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState<UserAccount | null>(null);
	const [showRequestModal, setShowRequestModal] = useState(false);

	const { friends, isLoading, error, removeFriend, sendFriendRequest } =
		useFriends();
	const {
		users,
		isLoading: isSearchLoading,
		error: searchError,
	} = useUserSearch(searchTerm);

	const filteredFriends = useMemo(() => {
		if (!friends) return [];
		if (!searchTerm) return friends;
		return friends?.filter((user) =>
			user.gamer_tag.toLowerCase().includes(searchTerm.toLowerCase()),
		);
	}, [friends, searchTerm]);

	const handleUnfollowConfirm = async () => {
		if (selectedFriend) {
			setShowUnfriendModal(false);
			removeFriend.mutate(selectedFriend.id);
		}
	};

	const handleRequestConfirm = async () => {
		if (selectedUser) {
			sendFriendRequest.mutate(selectedUser.id, {
				onSuccess: () => {
					setShowRequestModal(false);
				},
				onError: (error) => {
					console.error("Error sending friend request:", error);
					// Could add error handling UI here
					setShowRequestModal(false);
				},
			});
		}
	};

	const handleNavigation = (selectedFriend: Friend) => {
		navigate(ttRoutes.friendProfile(selectedFriend.id));
	};

	const toggleRemoveFriend = (friend: Friend) => {
		setSelectedFriend(friend);
		setShowUnfriendModal(true);
	};

	const toggleRequestFriend = (user: UserAccount) => {
		setSelectedUser(user);
		setShowRequestModal(true);
	};

	if (isLoading)
		return (
			<div className="flex justify-center items-center h-[600px]">
				<LoadingOverlay description="Loading friends..." />
			</div>
		);

	if (error) {
		return <APIError title="Error loading friends" error={error} />;
	}

	return (
		<>
			<div className="absolute w-full h-full top-10 blur-3xl z-[1] opacity-90">
				<BubblesBackground />
			</div>

			<div className="relative flex flex-col items-center justify-center pt-2 z-[10] w-full md:w-2/3 lg:w-1/2 mx-auto space-y-6 pb-60 px-6">
				<div className="w-full relative">
					<input
						type="text"
						placeholder="Search friends or users"
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						className="w-full p-3 pl-12 rounded-2xl border border-gray-500 bg-gray-800 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-cyan-500"
					/>
					<FontAwesomeIcon
						icon={faSearch}
						className="absolute top-1/2 left-4 ml-2 transform -translate-y-1/2 text-gray-400"
					/>
				</div>

				{searchTerm.length > 0 && (
					<div className="w-full space-y-4">
						<>
							<h2 className="text-white text-xl font-semibold">
								Search Results
							</h2>
							{isSearchLoading ? (
								<div className="bg-gradient-to-br from-[#383d44] to-[#181b21] rounded-2xl p-4 border border-[#2d3138] flex justify-center items-center h-16">
									<div className="text-gray-300">Searching users...</div>
								</div>
							) : searchError ? (
								<APIError title="Error searching users" error={searchError} />
							) : users && users.length > 0 ? (
								<div className="w-full space-y-3">
									{users.map((user) => (
										<div
											key={user.id}
											className="bg-gradient-to-br from-[#383d44] to-[#181b21] rounded-2xl p-3 border border-[#2d3138] flex items-center justify-between"
										>
											<div className="relative flex items-center space-x-4">
												<div className="flex items-center">
													<div className="flex flex-col items-center">
														<GradientBorder rounded="rounded-full">
															<img
																src={user.profile_image ?? ""}
																alt="Profile"
																className="rounded-full size-11 object-cover "
															/>
														</GradientBorder>
													</div>
													<p className="text-white ml-4">{user.gamer_tag}</p>
												</div>
											</div>

											<GradientBorder
												from="#FF5E00"
												to="#FFAA00"
												via={["#FFAA00"]}
											>
												<button
													type="button"
													aria-label="Request Friend"
													onClick={() => toggleRequestFriend(user)}
													className="flex items-center justify-center bg-transparent hover:text-orange-700 text-orange-500 p-2 rounded-full text-sm font-semibold shadow-md hover:opacity-90 transition-opacity duration-200"
												>
													<FontAwesomeIcon icon={faUserPlus} />
												</button>
											</GradientBorder>
										</div>
									))}
								</div>
							) : (
								<div className="bg-gradient-to-br from-[#383d44] to-[#181b21] rounded-2xl p-8 border border-[#2d3138] flex flex-col items-center justify-center w-full text-center">
									<FontAwesomeIcon
										icon={faUserSlash}
										className="text-gray-400 text-3xl mb-4"
									/>
									<h3 className="text-xl font-bold text-white mb-2">
										No Users Found
									</h3>
									<p className="text-gray-300">
										No users match your search "{searchTerm}"
									</p>
								</div>
							)}
						</>
					</div>
				)}

				<div className="w-full space-y-4">
					<h2 className="text-white text-xl font-semibold">Your Friends</h2>
					<div className="space-y-3">
						{filteredFriends.length > 0 ? (
							filteredFriends.map((friend) => (
								<button
									type="button"
									key={friend.id}
									onClick={() => handleNavigation(friend)}
									className="bg-gradient-to-br from-[#383d44] to-[#181b21] rounded-2xl p-3 border border-[#2d3138] flex items-center justify-between cursor-pointer transition-transform transform hover:scale-105 w-full"
								>
									<div className="relative flex items-center space-x-4">
										<div className="flex items-center">
											<div className="flex flex-col items-center">
												<GradientBorder rounded="rounded-full">
													<img
														src={friend.profile_image ?? ""}
														alt="Profile"
														className="rounded-full size-11 object-cover "
													/>
												</GradientBorder>
											</div>
											<p className="text-white ml-4">{friend.gamer_tag}</p>
										</div>
									</div>

									<GradientBorder from="#FF5E00" to="#FFAA00" via={["#FFAA00"]}>
										<button
											type="button"
											aria-label="Unfollow"
											onClick={(e) => {
												e.stopPropagation();
												toggleRemoveFriend(friend);
											}}
											className="flex items-center justify-center bg-transparent hover:text-orange-700 text-orange-500 p-2 rounded-full text-sm font-semibold shadow-md hover:opacity-90 transition-opacity duration-200"
										>
											<FontAwesomeIcon icon={faHeartBroken} />
										</button>
									</GradientBorder>
								</button>
							))
						) : (
							<div className="bg-gradient-to-br from-[#383d44] to-[#181b21] rounded-2xl p-8 border border-[#2d3138] flex flex-col items-center justify-center w-full text-center">
								<FontAwesomeIcon
									icon={faUserSlash}
									className="text-gray-400 text-3xl mb-4"
								/>
								<h3 className="text-xl font-bold text-white mb-2">
									No Friends Found
								</h3>
								<p className="text-gray-300">
									{searchTerm.length > 0
										? `No friends match your search "${searchTerm}"`
										: "You haven't added any friends yet. Try searching..."}
								</p>
							</div>
						)}
					</div>
				</div>

				{showUnfriendModal && selectedFriend && (
					<UnfriendModal
						isOpen={showUnfriendModal}
						name={selectedFriend.gamer_tag}
						onClose={() => setShowUnfriendModal(false)}
						onConfirm={handleUnfollowConfirm}
					/>
				)}

				{showRequestModal && selectedUser && (
					<RequestFriendModal
						isOpen={showRequestModal}
						name={selectedUser.gamer_tag}
						onClose={() => setShowRequestModal(false)}
						onConfirm={handleRequestConfirm}
						isLoading={sendFriendRequest.isPending}
					/>
				)}
			</div>
		</>
	);
}
