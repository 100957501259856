import { Container } from "../website/Container";
import { GradientBorder } from "../website/GradientContainer";
import { Layout } from "../website/Layout";

export default function NotFoundPage() {
	return (
		<Layout>
			<Container className="relative isolate flex h-full flex-col items-center justify-center py-20 text-center sm:py-32">
				<main className="grid min-h-full place-items-center relative px-6 py-24 sm:py-32 lg:px-8">
					<div className="text-center">
						<p className="text-5xl font-semibold text-transparent bg-clip-text bg-gradient-to-br from-cyan-500 via-blue-600 to-purple-500">
							404
						</p>
						<h1 className="mt-4 text-balance text-5xl font-semibold tracking-tight text-gray-100 sm:text-7xl">
							Page not found
						</h1>
						<p className="mt-6 text-pretty text-lg font-medium text-gray-300 sm:text-xl/8">
							Sorry, we couldn’t find the page you’re looking for.
						</p>
						<div className="mt-10 flex items-center justify-center gap-x-6">
							<GradientBorder padding="p-0.5" rounded="rounded-2xl">
								<a
									href="/"
									className="rounded-2xl px-4 py-2 text-lg font-semibold text-white shadow-sm"
								>
									Go back home
								</a>
							</GradientBorder>
						</div>
					</div>
				</main>
			</Container>
		</Layout>
	);
}
