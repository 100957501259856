import { Navigate } from "react-router-dom";
import { useAuth } from "../providers/authProvider";
import { ttRoutes } from "../hooks/useTTRouter";

export default function UnProtectedRoute({
	children,
}: {
	children: JSX.Element;
}): JSX.Element {
	const { isLoggedIn } = useAuth();
	const isEnabled = process.env.REACT_APP_DOWNLOAD_ENABLED !== "false";

	if (!isEnabled) {
		return <Navigate to={"/about"} replace />;
	}

	if (isLoggedIn) {
		// Redirect unauthenticated users to the login page
		return <Navigate to={ttRoutes.home()} replace />;
	}

	// Render the protected component
	return children;
}
