import styles from "./index.module.css";

import searchIconSrc from "./close.svg";

export const CloseIconButton = ({ onClick }: { onClick: () => void }) => {
	return (
		<button type="button" className={styles.close} onClick={onClick}>
			<img src={searchIconSrc} alt="Close" width={20} height={20} />
		</button>
	);
};
