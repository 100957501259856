import { useState } from "react";
import { useAccount } from "src/providers/accountProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faSearch } from "@fortawesome/free-solid-svg-icons";
import { GradientBorder } from "src/components/GradientContainer";
import type { Profile } from "src/types";
import useTTRouter from "src/hooks/useTTRouter";
import useFollowing from "src/hooks/useFollowing";
import { FollowModal } from "src/components/Modals";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";

export function FollowersPage() {
	const { follow } = useFollowing();
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedFollower, setSelectedFollower] = useState<Profile | null>(
		null,
	);
	const [isFollowModal, setIsFollowModal] = useState(false);
	const { followers } = useAccount() || {};
	const { navigateToUserProfile, navigateBack } = useTTRouter();

	const filteredFollowers = followers?.filter((follower) =>
		follower.gamertag.toLowerCase().includes(searchTerm.toLowerCase()),
	);

	const handleFollowConfirm = () => {
		if (selectedFollower) {
			setIsFollowModal(false);
			follow(selectedFollower.id);
		}
	};

	const handleNavigation = (follower: Profile) => {
		navigateToUserProfile(follower.id);
	};

	const toggleFollowModal = (follower: Profile) => {
		setSelectedFollower(follower);
		setIsFollowModal(true);
	};

	return (
		<div className="relative flex flex-col items-center justify-center pt-2 pb-60 z-[20] w-full md:w-2/3 lg:w-1/2 mx-auto space-y-6">
			<div className="relative flex items-center w-full space-x-4">
				<button
					aria-label="Back"
					type="button"
					onClick={() => navigateBack()}
					className="pl-1 bg-transparent text-white rounded-full hover:bg-gray-600"
				>
					<ChevronLeftIcon className="h-8 w-8" />
				</button>

				<div className="relative w-3/4">
					<input
						type="text"
						placeholder="Search followed users"
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
						className="w-full p-3 pl-12 rounded-2xl border border-gray-500 bg-gray-800 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-cyan-500"
					/>
					<FontAwesomeIcon
						icon={faSearch}
						className="absolute top-1/2 left-4 ml-2 transform -translate-y-1/2 text-gray-400"
					/>
				</div>
			</div>

			<div className="w-4/5 space-y-3">
				{filteredFollowers?.map((follower) => (
					<div
						key={follower.id}
						onClick={() => handleNavigation(follower)}
						className="bg-gradient-to-br from-[#383d44] to-[#181b21] rounded-2xl p-3 border border-[#2d3138] flex items-center justify-between cursor-pointer transition-transform transform hover:scale-105"
					>
						<div className="relative flex items-center space-x-4">
							<div className="flex items-center">
								<div className="flex flex-col items-center">
									<img
										src={follower.profileImage}
										alt="Profile"
										className="rounded-full h-10 w-10 object-cover border border-gray-500"
									/>
								</div>
								<p className="text-white ml-4">{follower.gamertag}</p>
							</div>
						</div>

						<GradientBorder>
							<button
								type="button"
								aria-label="Follow"
								onClick={(e) => {
									e.stopPropagation();
									toggleFollowModal(follower);
								}}
								className="flex items-center justify-center bg-transparent text-cyan-500 p-2 rounded-full text-sm font-semibold shadow-md hover:opacity-90 transition-opacity duration-200"
							>
								<FontAwesomeIcon icon={faHeart} />
							</button>
						</GradientBorder>
					</div>
				))}
			</div>

			{isFollowModal && selectedFollower && (
				<FollowModal
					isOpen={isFollowModal}
					followerName={selectedFollower.gamertag}
					onRequestClose={() => setIsFollowModal(false)}
					followConfirm={handleFollowConfirm}
				/>
			)}
		</div>
	);
}
