import {
	type ComponentPropsWithoutRef,
	forwardRef,
	type ReactNode,
} from "react";
import { cn } from "src/utils/cn";

export function AppScreen({
	children,
	className,
	...props
}: ComponentPropsWithoutRef<"div">) {
	return (
		<div className={cn("flex flex-col bg-tic-taps-grey", className)} {...props}>
			{children}
		</div>
	);
}

AppScreen.Header = forwardRef<React.ElementRef<"div">, { children: ReactNode }>(
	function AppScreenHeader({ children }, ref) {
		return (
			<div ref={ref} className=" text-white">
				{children}
			</div>
		);
	},
);

AppScreen.Title = forwardRef<React.ElementRef<"div">, { children: ReactNode }>(
	function AppScreenTitle({ children }, ref) {
		return (
			<div ref={ref} className="text-2xl text-white">
				{children}
			</div>
		);
	},
);

AppScreen.Subtitle = forwardRef<
	React.ElementRef<"div">,
	{ children: ReactNode }
>(function AppScreenSubtitle({ children }, ref) {
	return (
		<div ref={ref} className="text-sm text-gray-500">
			{children}
		</div>
	);
});

AppScreen.Body = forwardRef<
	React.ElementRef<"div">,
	{ className?: string; children: ReactNode }
>(function AppScreenBody({ children, className }, ref) {
	return <>{children}</>;
});
