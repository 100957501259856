import type {
	PaymentCreateRequest,
	TransactionCurrencyCode,
} from "src/api/types";
import { Post } from "../../fetch.wrapper";
import { routes } from "../../routes";

export default async function CreatePayment(
	amount: number,
	currency_code: TransactionCurrencyCode,
): Promise<string> {
	const data = await Post<PaymentCreateRequest>(routes.createPayment(), {
		amount,
		currency_code,
	});

	return data.checkout_url;
}
