import * as Sentry from "@sentry/react";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import {
	isTTError,
	NotificationProvider,
} from "./providers/notificationProvider";
import { AuthProvider } from "./providers/authProvider";
import { AccountProvider } from "./providers/accountProvider";
import { ErrorPage } from "./components/ErrorPage";
import "@fontsource-variable/montserrat";
import { registerServiceWorker } from "./registerServiceWorker";

Sentry.init({
	dsn: "https://685b81b02a3a7eb1d9f28d0277444d34@o4509066051911680.ingest.de.sentry.io/4509066053943376",
});

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

const queryClient = new QueryClient();

function Index() {
	queryClient.setDefaultOptions({
		queries: {
			staleTime: Number.POSITIVE_INFINITY,
			refetchOnWindowFocus: false,
			retry: false,
			throwOnError: (error) => {
				console.error(error);
				if (isTTError(error)) {
					if (error.code.includes("401") || error.code === "401") {
						localStorage.removeItem("token");
						localStorage.setItem("isLoggedIn", "false");
						window.location.reload();
						return false;
					}
				}
				return true;
			},
		},
	});

	return (
		<Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog>
			<AuthProvider>
				<NotificationProvider>
					<QueryClientProvider client={queryClient}>
						<AccountProvider>
							<RouterProvider router={router} />
						</AccountProvider>
					</QueryClientProvider>
				</NotificationProvider>
			</AuthProvider>
		</Sentry.ErrorBoundary>
	);
}

root.render(
	<StrictMode>
		<Index />
	</StrictMode>,
);

registerServiceWorker();
