import { useEffect, useState, useCallback } from "react";

declare global {
	interface WindowEventMap {
		beforeinstallprompt: BeforeInstallPromptEvent;
	}
}

interface BeforeInstallPromptEvent extends Event {
	prompt: () => Promise<void>;
	userChoice: Promise<{ outcome: "accepted" | "dismissed" }>;
}

export function useInstallPWA() {
	const [installPromptEvent, setInstallPromptEvent] =
		useState<BeforeInstallPromptEvent | null>(null);

	useEffect(() => {
		const handleBeforeInstallPrompt = (event: BeforeInstallPromptEvent) => {
			event.preventDefault();
			setInstallPromptEvent(event);
		};

		window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

		return () => {
			window.removeEventListener(
				"beforeinstallprompt",
				handleBeforeInstallPrompt,
			);
		};
	}, []);

	const install = useCallback(() => {
		if (!installPromptEvent) return;
		installPromptEvent.prompt();
		installPromptEvent.userChoice.then((choiceResult) => {
			setInstallPromptEvent(null);
		});
	}, [installPromptEvent]);

	return { install };
}
