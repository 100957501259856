import { useQuery } from "@tanstack/react-query";
import { Get } from "src/api/fetch.wrapper";
import { routes } from "src/api/routes";
import type { Friend, PartyHistory } from "src/api/types";

export const useFriend = (friendId?: string) => {
	const friendResult = useQuery<Friend>({
		queryKey: ["friend", friendId],
		queryFn: () => Get(`${routes.friends()}${friendId}`),
		enabled: !!friendId,
	});

	const friendHistory = useQuery<PartyHistory>({
		queryKey: ["friendHistory", friendResult.data?.user_id],
		queryFn: ({ queryKey }) =>
			Get(`${routes.partiesHistory()}?user_id=${queryKey[1]}`),
		enabled: !!friendResult.data?.user_id,
	});

	return {
		friend: friendResult.data,
		isLoading: friendResult.isLoading || friendHistory.isLoading,
		error: friendResult.error || friendHistory.error,
		history: friendHistory.data,
	};
};
