import type { ReactNode } from "react";
import { Sheet } from "react-modal-sheet";
import { CloseIconButton } from "../CloseIconButton";
import styles from "./index.module.css";

export const ListSheet = ({
	isOpen,
	onClose,
	title,
	children,
	bg,
}: {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	children: ReactNode;
	bg?: string;
}) => {
	return (
		<Sheet
			isOpen={isOpen}
			onClose={onClose}
			snapPoints={[600, 400, 100, 0]}
			initialSnap={0}
			detent="content-height"
			disableDrag={false}
		>
			<Sheet.Backdrop className={styles.backdrop} />
			<Sheet.Container
				style={{
					backgroundColor: "#25262C",
					backgroundImage: `url(${bg})`,
					borderTopRightRadius: "24px",
					borderTopLeftRadius: "24px",
					overflow: "hidden",
				}}
				className={styles.sheetContent}
			>
				<Sheet.Header className={styles.header}>
					<div className={styles.title}>{title}</div>
					<CloseIconButton onClick={onClose} />
				</Sheet.Header>
				<Sheet.Content className={styles.content}>{children}</Sheet.Content>
			</Sheet.Container>
			<Sheet.Backdrop onTap={onClose} />
		</Sheet>
	);
};
