import { Post } from "../../fetch.wrapper";
import { routes } from "../../routes";

export async function SaveHighscore(
	partyId: string,
	score: number,
	trophies: number,
) {
	await Post(routes.savePartyLeaderboardEntry(partyId), {
		score: score,
		xp: trophies,
	});

	return true;
}
