"use client";

import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Tab, TabGroup, TabList, TabPanels } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import party from "./images/Party.png";
import leaderboard from "./images/Leaderboard.png";
import createParty from "./images/createparty.png";
import { AppScreen } from "./AppScreen";
import { CircleBackground } from "./CircleBackground";
import { PhoneFrame } from "./PhoneFrame";
import { Container } from "./Container";
import { cn } from "src/utils/cn";

interface CustomAnimationProps {
	isForwards: boolean;
	changeCount: number;
}

const features = [
	{
		name: "Create a party!",
		description:
			"Set up your party, customize the rules, and challenge your friends to compete for the win!",
		icon: DeviceUserIcon,
		screen: InviteScreen,
	},
	{
		name: "Challenge Your Friends & Family!",
		description:
			"Invite your friends and family to your party and compete in a thrilling minigames tournament. Who will rise to the top? Play, win, and claim victory!",
		icon: DeviceUserIcon,
		screen: PartyScreen,
	},
	{
		name: "Win & Claim Your Rewards!",
		description:
			"After competing in minigame tournaments, check if you’ve secured a prize! If you win, your rewards are instantly added to your balance—play, win, and cash in!",
		icon: DeviceNotificationIcon,
		screen: LeaderBoardScreen,
	},
];

function DeviceUserIcon(props: React.ComponentPropsWithoutRef<"svg">) {
	return (
		<svg viewBox="0 0 32 32" aria-hidden="true" {...props}>
			<circle cx={16} cy={16} r={16} fill="#A3A3A3" fillOpacity={0.2} />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16 23a3 3 0 100-6 3 3 0 000 6zm-1 2a4 4 0 00-4 4v1a2 2 0 002 2h6a2 2 0 002-2v-1a4 4 0 00-4-4h-2z"
				fill="#737373"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5 4a4 4 0 014-4h14a4 4 0 014 4v24a4.002 4.002 0 01-3.01 3.877c-.535.136-.99-.325-.99-.877s.474-.98.959-1.244A2 2 0 0025 28V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 001.041 1.756C8.525 30.02 9 30.448 9 31s-.455 1.013-.99.877A4.002 4.002 0 015 28V4z"
				fill="#A3A3A3"
			/>
		</svg>
	);
}

function DeviceNotificationIcon(props: React.ComponentPropsWithoutRef<"svg">) {
	return (
		<svg viewBox="0 0 32 32" aria-hidden="true" {...props}>
			<circle cx={16} cy={16} r={16} fill="#A3A3A3" fillOpacity={0.2} />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9 0a4 4 0 00-4 4v24a4 4 0 004 4h14a4 4 0 004-4V4a4 4 0 00-4-4H9zm0 2a2 2 0 00-2 2v24a2 2 0 002 2h14a2 2 0 002-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9z"
				fill="#A3A3A3"
			/>
			<path
				d="M9 8a2 2 0 012-2h10a2 2 0 012 2v2a2 2 0 01-2 2H11a2 2 0 01-2-2V8z"
				fill="#737373"
			/>
		</svg>
	);
}

type ScreenProps =
	| {
			animated: true;
			custom: CustomAnimationProps;
	  }
	| { animated?: false };

function InviteScreen(props: ScreenProps) {
	return (
		<AppScreen className="w-full h-full overflow-hidden">
			<img
				src={createParty}
				alt="createParty"
				className="w-full h-full max-w-full max-h-full object-contain rounded-3xl"
			/>
		</AppScreen>
	);
}

function LeaderBoardScreen(props: ScreenProps) {
	return (
		<AppScreen className="w-full h-full overflow-hidden">
			<img
				src={leaderboard}
				alt="leaderboard"
				className="w-full pb-4 h-full max-w-full max-h-full object-contain rounded-3xl"
			/>
		</AppScreen>
	);
}

function PartyScreen(props: ScreenProps) {
	return (
		<AppScreen className="w-full h-full overflow-hidden">
			<img
				src={party}
				alt="Party"
				className="w-full h-full max-w-full max-h-full object-contain rounded-3xl"
			/>
		</AppScreen>
	);
}

function usePrevious<T>(value: T) {
	const ref = useRef<T>();

	useEffect(() => {
		ref.current = value;
	}, [value]);

	return ref.current;
}

function FeaturesDesktop() {
	const [changeCount, setChangeCount] = useState(0);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const prevIndex = usePrevious(selectedIndex);
	const isForwards = prevIndex === undefined ? true : selectedIndex > prevIndex;

	const onChange = useCallback(
		(index: number) => {
			setTimeout(() => {
				setSelectedIndex(index);
				setChangeCount((changeCount) => changeCount + 1);
			}, 100);
		},
		[setSelectedIndex, setChangeCount],
	);

	return (
		<TabGroup
			className="grid grid-cols-12 items-center gap-8 lg:gap-16 xl:gap-24"
			selectedIndex={selectedIndex}
			onChange={onChange}
			vertical
		>
			<TabList className="relative z-10 order-last col-span-6 space-y-6">
				{features.map((feature, featureIndex) => (
					<div
						key={feature.name}
						className="relative rounded-2xl transition-colors hover:bg-tic-taps-grey/30"
					>
						{featureIndex === selectedIndex && (
							<motion.div
								layoutId="activeBackground"
								className="absolute inset-0 bg-tic-taps-grey"
								initial={{ borderRadius: 16 }}
							/>
						)}
						<div className="relative z-10 p-8">
							<feature.icon className="h-8 w-8" />
							<h3 className="mt-6 text-lg font-semibold text-white">
								<Tab className="text-left ui-not-focus-visible:outline-none">
									<span className="absolute inset-0 rounded-2xl" />
									{feature.name}
								</Tab>
							</h3>
							<p className="mt-2 text-sm text-gray-400">
								{feature.description}
							</p>
						</div>
					</div>
				))}
			</TabList>
			<div className="relative col-span-6">
				<div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
					<CircleBackground color="#DB05EB" className="animate-spin-slower" />
				</div>
				<PhoneFrame className="relative mx-auto w-full max-w-[366px]">
					<TabPanels as={Fragment}>
						<AnimatePresence
							initial={false}
							custom={{ isForwards, changeCount }}
						>
							{features.map((feature, featureIndex) =>
								selectedIndex === featureIndex ? (
									<feature.screen
										key={feature.name}
										animated
										custom={{ isForwards, changeCount }}
									/>
								) : null,
							)}
						</AnimatePresence>
					</TabPanels>
				</PhoneFrame>
			</div>
		</TabGroup>
	);
}

function FeaturesMobile() {
	const [activeIndex, setActiveIndex] = useState(0);
	const slideContainerRef = useRef<React.ElementRef<"div">>(null);
	const slideRefs = useRef<Array<React.ElementRef<"div">>>([]);

	useEffect(() => {
		const observer = new window.IntersectionObserver(
			(entries) => {
				for (const entry of entries) {
					if (entry.isIntersecting && entry.target instanceof HTMLDivElement) {
						setActiveIndex(slideRefs.current.indexOf(entry.target));
						break;
					}
				}
			},
			{
				root: slideContainerRef.current,
				threshold: 0.6,
			},
		);

		for (const slide of slideRefs.current) {
			if (slide) {
				observer.observe(slide);
			}
		}

		return () => {
			observer.disconnect();
		};
	}, [slideContainerRef, slideRefs]);

	return (
		<>
			<div
				ref={slideContainerRef}
				className="-mb-4 flex snap-x snap-mandatory -space-x-4 overflow-x-auto overscroll-x-contain scroll-smooth pb-4 [scrollbar-width:none] sm:-space-x-6 [&::-webkit-scrollbar]:hidden"
			>
				{features.map((feature, featureIndex) => (
					<div
						key={featureIndex}
						ref={(ref) => ref && (slideRefs.current[featureIndex] = ref)}
						className="w-full flex-none snap-center px-4 sm:px-6"
					>
						<div className="relative transform overflow-hidden rounded-2xl bg-tic-taps-grey px-5 py-6">
							<div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
								<CircleBackground
									color="#13B5C8"
									className={featureIndex % 2 === 1 ? "rotate-180" : undefined}
								/>
							</div>
							<PhoneFrame className="relative mx-auto w-full max-w-[366px]">
								<feature.screen />
							</PhoneFrame>
							<div className="absolute inset-x-0 bottom-0 bg-tic-taps-grey/95 p-6 backdrop-blur sm:p-10">
								<feature.icon className="h-8 w-8" />
								<h3 className="mt-6 text-sm font-semibold text-white sm:text-lg">
									{feature.name}
								</h3>
								<p className="mt-2 text-sm text-gray-400">
									{feature.description}
								</p>
							</div>
						</div>
					</div>
				))}
			</div>
			<div className="mt-6 flex justify-center gap-3">
				{features.map((_, featureIndex) => (
					<button
						type="button"
						key={featureIndex}
						className={cn(
							"relative h-0.5 w-4 rounded-full",
							featureIndex === activeIndex ? "bg-gray-300" : "bg-gray-500",
						)}
						aria-label={`Go to slide ${featureIndex + 1}`}
						onClick={() => {
							slideRefs.current[featureIndex].scrollIntoView({
								block: "nearest",
								inline: "nearest",
							});
						}}
					>
						<span className="absolute -inset-x-1.5 -inset-y-3" />
					</button>
				))}
			</div>
		</>
	);
}

export function PrimaryFeatures() {
	return (
		<section
			id="features"
			aria-label="features"
			className="bg-zinc-900 py-20 sm:py-32"
		>
			<Container>
				<div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-3xl">
					{/* <h2 className="text-3xl font-medium tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-cyan-500 via-blue-600 to-purple-500">
    Unleash Your Potential in the Crypto Gaming Arena!
     </h2> */}
					<h2 className="text-3xl font-medium tracking-tight text-white">
						How it works
					</h2>
				</div>
			</Container>
			<div className="mt-16 md:hidden">
				<FeaturesMobile />
			</div>
			<Container className="hidden md:mt-20 md:block">
				<FeaturesDesktop />
			</Container>
		</section>
	);
}
