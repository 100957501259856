import { useMutation, useQuery } from "@tanstack/react-query";
import { Delete, Get, Post } from "src/api/fetch.wrapper";
import { routes } from "src/api/routes";
import type { Friend } from "src/api/types";

const DEFAULT_PAGE_SIZE = 100; // TODO: Switch to loading on scroll once we have the API based search available

export const useFriends = () => {
	const friendsResult = useQuery<Array<Friend>>({
		queryKey: ["friends"],
		queryFn: () => Get(`${routes.friends()}?page_size=${DEFAULT_PAGE_SIZE}`),
	});

	const removeFriend = useMutation({
		mutationFn: (friendId: string) => Delete(`${routes.friends()}${friendId}`),
		onSettled: () => {
			friendsResult.refetch();
		},
	});

	const sendFriendRequest = useMutation({
		mutationFn: (userId: string) =>
			Post("/friends/requests", { user_id: userId }),
	});

	return {
		friends: friendsResult.data,
		isLoading: friendsResult.isLoading,
		error: friendsResult.error,
		sendFriendRequest,
		removeFriend,
	};
};
