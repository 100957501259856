import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Get, Post } from "src/api/fetch.wrapper";
import type { Notification } from "src/api/types";
const DEFAULT_PAGE_SIZE = 100;

export const useNotificationsInbox = (isEnabled = false, onlyUnread = true) => {
	const queryClient = useQueryClient();
	const { data, isLoading, error } = useQuery<Notification[]>({
		queryKey: ["notifications-inbox", onlyUnread],
		queryFn: ({ queryKey }) => {
			const [, onlyUnread] = queryKey;
			return Get(
				`/notifications/inbox?unread_only=${onlyUnread}&page_size=${DEFAULT_PAGE_SIZE}`,
			);
		},
		enabled: isEnabled,
	});

	const markAllAsReadMutation = useMutation({
		mutationFn: () => Post("/notifications/inbox/read-all", {}),
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ["notifications-inbox", onlyUnread],
			});
		},
	});

	return {
		notifications: data,
		markAllAsReadMutation,
		isLoading,
		error,
	};
};
