import { Capacitor } from "@capacitor/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ttRoutes } from "../hooks/useTTRouter";

export default function PlatformRedirectPage() {
	const navigate = useNavigate();
	useEffect(() => {
		const platform = Capacitor.getPlatform();
		const isAppDisabled = process.env.REACT_APP_DOWNLOAD_ENABLED === "false";
		if (platform === "web" || isAppDisabled) navigate("/about");
		else navigate(ttRoutes.login());
	}, [navigate]);
	return <></>;
}
