import { useId } from "react";
import BubblesBackground from "./BubblesBackground";
import { Header } from "./Header";
import { Container } from "./Container";
import { PhoneFrame } from "./PhoneFrame";
import { AppDemo } from "./AppDemo";
import { InstallButton } from "./InstallButton";

function BackgroundIllustration(props: React.ComponentPropsWithoutRef<"div">) {
	const id = useId();

	return (
		<div {...props}>
			<svg
				viewBox="0 0 1026 1026"
				fill="none"
				aria-hidden="true"
				className="absolute inset-0 h-full w-full animate-spin-slow"
			>
				<path
					d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z"
					stroke="#D4D4D4"
					strokeOpacity="0.7"
				/>
				<path
					d="M513 1025C230.23 1025 1 795.77 1 513"
					stroke={`url(#${id}-gradient-1)`}
					strokeLinecap="round"
				/>
				<defs>
					<linearGradient
						id={`${id}-gradient-1`}
						x1="0%"
						y1="0%"
						x2="100%"
						y2="100%"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0%" stopColor="#06b6d4" />
						<stop offset="50%" stopColor="#2563EB" />
						<stop offset="100%" stopColor="#9333EA" stopOpacity="0" />
					</linearGradient>
				</defs>
			</svg>
			<svg
				viewBox="0 0 1026 1026"
				fill="none"
				aria-hidden="true"
				className="absolute inset-0 h-full w-full animate-spin-reverse-slower"
			>
				<path
					d="M913 513c0 220.914-179.086 400-400 400S113 733.914 113 513s179.086-400 400-400 400 179.086 400 400Z"
					stroke="#D4D4D4"
					strokeOpacity="0.7"
				/>
				<path
					d="M913 513c0 220.914-179.086 400-400 400"
					stroke={`url(#${id}-gradient-2)`}
					strokeLinecap="round"
				/>
				<defs>
					<linearGradient
						id={`${id}-gradient-2`}
						x1="0%"
						y1="0%"
						x2="100%"
						y2="100%"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0%" stopColor="#06b6d4" />
						<stop offset="50%" stopColor="#2563EB" />
						<stop offset="100%" stopColor="#9333EA" stopOpacity="0" />
					</linearGradient>
				</defs>
			</svg>
		</div>
	);
}

export function HeaderHero() {
	return (
		<>
			<div
				className="relative overflow-hidden pt-4 lg:pb-32 xl:pb-36 bg-tic-taps-grey"
				id="hero"
			>
				<div className="pb-20">
					<Header />
				</div>
				<div className="absolute -top-0 left-1/2 transform -translate-x-1/2 overflow-hidden w-full opacity-75 -scale-x-100 blur-3xl z-0">
					<BubblesBackground />
				</div>
				<Container>
					<div className="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
						<div className="relative z-10 mx-auto max-w-2xl lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6">
							<h1 className="text-4xl font-medium tracking text-white text-center lg:text-left">
								Host. Invite. Win.
							</h1>
							<p className="mt-6 text-lg text-gray-300 text-justify">
								Create a party, challenge your friends, and dominate the
								leaderboard. Play, compete, and claim your victory!
							</p>
							<div className="mt-8 flex flex-wrap gap-x-6 gap-y-4 justify-center lg:justify-start">
								<InstallButton />
							</div>
						</div>
						<div className="relative mt-10 sm:mt-20 lg:col-span-5 lg:row-span-2 lg:mt-0 xl:col-span-6">
							<BackgroundIllustration className="absolute left-1/2 top-4 h-[1026px] w-[1026px] -translate-x-1/3 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:-top-14 xl:ml-0" />
							<div className="-mx-4 h-[448px] px-9 [mask-image:linear-gradient(to_bottom,white_60%,transparent)] sm:mx-0 lg:absolute lg:-inset-x-10 lg:-bottom-20 lg:-top-10 lg:h-auto lg:px-0 lg:pt-10 xl:-bottom-32">
								<PhoneFrame className="mx-auto max-w-[366px]" priority>
									<AppDemo />
								</PhoneFrame>
							</div>
						</div>
					</div>
				</Container>
			</div>
		</>
	);
}
