import type { ComponentPropsWithoutRef } from "react";
import { cn } from "src/utils/cn";

import styles from "./index.module.css";

export const ActionButton = ({
	children,
	className,
	...props
}: ComponentPropsWithoutRef<"button">) => {
	return (
		<button
			className={cn(styles.actionButton, className)}
			type="button"
			{...props}
		>
			{children}
		</button>
	);
};
