import { useEffect } from "react";
import { ring } from "ldrs";

ring.register();

const LoadingRing = ({
	size = 30,
	stroke = 4,
	color = "cyan",
	speed = 2,
}: {
	size?: number;
	stroke?: number;
	color?: string;
	speed?: number;
}) => {
	useEffect(() => {}, []);

	return (
		<l-ring
			size={size.toString()}
			stroke={stroke.toString()}
			bg-opacity="0"
			speed={speed.toString()}
			color={color}
		/>
	);
};

export default LoadingRing;
