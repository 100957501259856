import type { ReactNode } from "react";
import styles from "./index.module.css";
export const RowActionButton = ({
	onClick,
	children,
	variant = "primary",
	disabled = false,
}: {
	onClick: () => void;
	children: ReactNode;
	variant?: "primary" | "secondary";
	disabled?: boolean;
}) => {
	return (
		<button
			className={styles.button}
			type="button"
			onClick={onClick}
			data-variant={variant}
			disabled={disabled}
		>
			{children}
		</button>
	);
};
