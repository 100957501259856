import { useNotification } from "src/hooks/useNotification";
import type { Notification } from "../../api/types";
import { ListSheet } from "../ListSheet";

import styles from "./index.module.css";
import { RowActionButton } from "../RowActionButton";
import { ActionButton } from "../ActionButton";

export const NotificationDetailSheet = ({
	notification,
	isOpen,
	onClose,
}: {
	notification: Notification | null;
	isOpen: boolean;
	onClose: () => void;
}) => {
	const {
		markAsReadMutation,
		declineFriendRequestMutation,
		acceptFriendRequestMutation,
	} = useNotification();
	if (!notification) return null;

	const isUnread = notification.status === "UNREAD";

	return (
		<ListSheet isOpen={isOpen} onClose={onClose} title={notification.title}>
			<p className={styles.description}>{notification.body}</p>
			{notification.notification_type === "friend_request_received" && (
				<div className={styles.actions}>
					<RowActionButton
						variant="secondary"
						onClick={() => {
							onClose();
							// declineFriendRequestMutation.mutate(notification);
						}}
					>
						Reject
					</RowActionButton>
					<RowActionButton
						onClick={() => {
							onClose();
							// acceptFriendRequestMutation.mutate(notification);
						}}
					>
						Accept
					</RowActionButton>
				</div>
			)}
			{isUnread && (
				<ActionButton
					disabled={markAsReadMutation.isPending}
					className={styles.markAsReadButton}
					onClick={() =>
						markAsReadMutation.mutate(notification, {
							onSettled: () => {
								onClose();
							},
						})
					}
				>
					{markAsReadMutation.isPending ? "Marking as read..." : "Mark as read"}
				</ActionButton>
			)}
		</ListSheet>
	);
};
