import searchIconSrc from "./search.svg";
import styles from "./index.module.css";
import { type ComponentRef, useRef } from "react";

export const SearchInput = ({
	value,
	onChange,
	placeholder,
	disabled,
}: {
	value: string;
	onChange: (value: string) => void;
	placeholder: string;
	disabled?: boolean;
}) => {
	const inputRef = useRef<ComponentRef<"input">>(null);

	return (
		<form
			className={styles.searchContainer}
			onSubmit={(e) => {
				e.preventDefault();
				onChange(inputRef.current?.value ?? "");
			}}
		>
			<button
				type="button"
				className={styles.searchIcon}
				onClick={() => inputRef.current?.focus()}
			>
				<img src={searchIconSrc} alt="Search" width={20} height={20} />
			</button>
			<input
				ref={inputRef}
				type="search"
				className={styles.searchInput}
				value={value}
				onChange={(e) => onChange(e.target.value)}
				placeholder={placeholder}
				disabled={disabled}
			/>
		</form>
	);
};
