const base = "https://api.tictaps.dev/v1";

export const routes = {
	me: () => `${base}/users/me`,
	authenticate: () => `${base}/users/authenticate`,
	user: (id: string) => `${base}/users/profiles/${id}`,
	games: () => `${base}/games/`,
	parties: () => `${base}/parties/`,
	createParty: () => `${base}/parties/`,
	friends: () => `${base}/friends/`,
	friendsCount: () => `${base}/friends/count`,
	partyLeaderboard: (id: string) => `${base}/parties/${id}/leaderboard`,
	savePartyLeaderboardEntry: (id: string) =>
		`${base}/parties/${id}/leaderboard`,
	party: (id: string) => `${base}/parties/${id}`,
	joinPartyById: () => `${base}/parties/join`,
	leavePartyById: (id: string) => `${base}/parties/${id}/leave`,
	closePartyById: (id: string) => `${base}/parties/${id}/close`,
	partiesHistory: () => `${base}/parties/history`,

	getParticipants: (id: string) => `${base}/parties/${id}/participants`,

	followUser: (id: string) => `${base}/follow/${id}`,
	getFollowers: (id: string) => `${base}/follow/${id}/followers`,
	getFollowedUsers: (id: string) => `${base}/follow/${id}/following`,
	getFollowing: (id: string) => `${base}/follow/${id}/following`,
	getIsFollowing: (myUserId: string, targetUserId: string) =>
		`${base}/follow/${myUserId}/following?target_user_id=${targetUserId}`,
	unfollowUser: (id: string) => `${base}/follow/${id}`,

	fundWallet: () => `${base}/wallets/fund`,
	walletBalance: () => `${base}/wallets/balance`,

	partyByCode: (code: string) => `${base}/parties/codes/${code}`,
	createPayment: () => `${base}/payments`,
};
