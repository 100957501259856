import { ReactComponent as LeaderboardBackgroundSVG } from "../../../assets/leaderboardBackground.svg";
import { ReactComponent as OneBubbleBackground } from "../../../assets/oneBubbleBackground.svg";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate, useParams } from "react-router-dom";
import Place from "src/components/Podium";
import ScoresList from "src/components/ScoresList";
import { useNoBackground } from "src/providers/layoutProvider";
import {
	PartyProvider,
	useParty,
	usePartyParticipants,
} from "src/providers/partyProvider";
import useTTRouter from "src/hooks/useTTRouter";
import { Participant, Party } from "src/types";

export default function LeaderboardPage() {
	const { partyId } = useParams();

	if (!partyId) {
		return <></>;
	}

	return (
		<PartyProvider partyId={partyId}>
			<LeaderboardContent />
		</PartyProvider>
	);
}
function sortParticipantsByXP(participants: Participant[]) {
	return participants.sort((a, b) => b.profile.xp - a.profile.xp);
}

function LeaderboardContent() {
	useNoBackground();
	const { party, isPartyQueryLoading } = useParty();
	const { participants } = usePartyParticipants();
	const sortedParticipants = sortParticipantsByXP(participants);
	const [firstPlace, secondPlace, thirdPlace, ...remainingParticipants] =
		sortedParticipants;
	const navigate = useNavigate();
	const { navigateToUserProfile, navigateBack } = useTTRouter();

	const handleBackClick = () => {
		navigate(-1);
	};

	return (
		<div className="relative w-full h-screen">
			{party && <LeaderboardBackground party={party} />}
			<div className="w-full">
				<button
					aria-label="Back"
					onClick={() => navigateBack()}
					className="px-2 left-4 mt-1 bg-transparent text-white rounded-full hover:bg-gray-600"
				>
					<ChevronLeftIcon className="h-8 w-8" />
				</button>
			</div>
			<div className="absolute w-full h-full flex justify-center">
				<div className="absolute z-[50] top-4 w-full flex items-center">
					<button
						aria-label="Back"
						onClick={handleBackClick}
						className="px-2 left-4 mt-1 bg-transparent text-white rounded-full hover:bg-gray-600"
					>
						<ChevronLeftIcon className="h-8 w-8" />
					</button>
					<h1 className="flex-grow text-center text-white font-light mr-12 text-xl tracking-wide">
						LEADERBOARD
					</h1>
				</div>

				<div className="w-full md:w-2/3 lg:w-1/2 pt-8">
					<div className="w-full flex items-end justify-center h-fit pt-4 pb-4">
						<Place score={secondPlace} place={2} />
						<Place score={firstPlace} place={1} />
						<Place score={thirdPlace} place={3} />
					</div>
					<ScoresList
						seeParticipantsProfile={(participant: Participant) =>
							navigateToUserProfile(participant.profile.id)
						}
						isParticipantsLoading={isPartyQueryLoading}
						participants={remainingParticipants}
					/>
				</div>
			</div>
		</div>
	);
}

function LeaderboardBackground({ party }: { party: Party }) {
	return (
		<div className="w-full absolute z-0 h-2/3 overflow-hidden">
			<div className="h-screen grid-cols-2 mt-0 blur-sm gap-0 grid-rows-7 md:grid-rows-6 grid relative overflow-hidden z-[5] w-full md:w-2/3 lg:w-1/2 mx-auto">
				<div className="h-fit opacity-50">
					<LeaderboardBackgroundSVG />
				</div>
				<div className="h-fit opacity-50">
					<LeaderboardBackgroundSVG />
				</div>
				<div className="h-fit opacity-40">
					<LeaderboardBackgroundSVG />
				</div>
				<div className="h-fit opacity-40">
					<LeaderboardBackgroundSVG />
				</div>
			</div>
			<div className="absolute -top-40 md:-top-80 right-0 h-full w-full -scale-x-100 blur-sm opacity/90 z-[2]">
				<OneBubbleBackground />
			</div>
		</div>
	);
}
