import type { Party } from "../types";
import { GradientBorder } from "./GradientContainer";
import { useState } from "react";
import { usePartyParticipants } from "../providers/partyProvider";
import {
	ArrowRightEndOnRectangleIcon,
	UserGroupIcon,
	UserPlusIcon,
} from "@heroicons/react/24/solid";
import { LoadingSpinner } from "./LoadingOverlay";
import { InviteFriendsSheet } from "./InviteFriendsSheet";
import { SharePartySheet } from "./SharePartySheet";

export function ParticipationControls({
	party,
	isOwner,
	isParticipant,
	isPartyActive,
	hasEnded,
	onPartyJoin,
	onLeaveParty,
	isLoading,
}: {
	party: Party;
	isOwner: boolean;
	isParticipant: boolean;
	isPartyActive: boolean;
	hasEnded: boolean;
	onPartyJoin(party: Party): void;
	onLeaveParty(party: Party): void;
	isLoading?: boolean;
}) {
	if (isOwner) {
		return <ParticipantsControls hasEnded={hasEnded} party={party} />;
	}

	if (isParticipant) {
		return (
			<button
				type="button"
				disabled={!isPartyActive || hasEnded}
				onClick={() => onLeaveParty(party)}
				className="w-full"
			>
				<GradientBorder
					disabled={hasEnded || !isPartyActive}
					from="#00FFAA"
					via={["#4579F5"]}
					to={"#9C42F5"}
				>
					<div className="bg-tic-taps-secondary-grey px-4 py-1 w-full flex items-center justify-center">
						{isLoading ? (
							<span className="pt-1">
								<LoadingSpinner />
							</span>
						) : (
							<>
								<ArrowRightEndOnRectangleIcon
									className={`${
										hasEnded ? "text-gray-500" : "text-white"
									} size-6 `}
								/>
								<p
									className={`${
										hasEnded ? "text-gray-500" : "text-white"
									} self-center font-semibold`}
								>
									Leave
								</p>
							</>
						)}
					</div>
				</GradientBorder>
			</button>
		);
	}

	return (
		<button
			type="button"
			disabled={!isPartyActive || hasEnded}
			onClick={() => onPartyJoin(party)}
			className="w-full"
		>
			<GradientBorder
				disabled={hasEnded || !isPartyActive}
				from={"#00FFAA"}
				via={["#4579F5"]}
				to={"#9C42F5"}
			>
				<div className="bg-tic-taps-secondary-grey px-4 py-1 w-full flex items-center justify-center space-x-2">
					{isLoading ? (
						<span className="pt-1">
							<LoadingSpinner />
						</span>
					) : (
						<>
							<UserGroupIcon
								className={`${
									hasEnded ? "text-gray-500" : "text-white"
								} size-6`}
							/>
							<p
								className={`${
									hasEnded ? "text-gray-500" : "text-white"
								} self-center font-semibold`}
							>
								Join
							</p>
						</>
					)}
				</div>
			</GradientBorder>
		</button>
	);
}

function ParticipantsControls({
	hasEnded,
	party,
}: {
	hasEnded: boolean;
	party: Party;
}) {
	const { participants } = usePartyParticipants();
	const [showInviteFriendsSheet, setShowInviteFriendsSheet] = useState(false);
	const [showShareSheet, setShowShareSheet] = useState(false);

	return (
		<>
			<button
				type="button"
				disabled={hasEnded}
				onClick={() => setShowInviteFriendsSheet(true)}
				className="w-full"
			>
				<GradientBorder
					disabled={hasEnded}
					from="#00FFAA"
					via={["#4579F5"]}
					to={"#9C42F5"}
				>
					<div className="bg-tic-taps-secondary-grey px-4 py-1 w-full flex items-center justify-center space-x-2">
						<UserPlusIcon
							className={`${hasEnded ? "text-gray-500" : "text-white"} size-6`}
						/>
						<p
							className={`${
								hasEnded ? "text-gray-500" : "text-white"
							}  self-center font-semibold`}
						>
							Invite
						</p>
					</div>
				</GradientBorder>
			</button>

			<InviteFriendsSheet
				isOpen={showInviteFriendsSheet}
				onClose={() => setShowInviteFriendsSheet(false)}
				participants={participants}
				onShare={() => {
					setShowShareSheet(true);
					setShowInviteFriendsSheet(false);
				}}
			/>

			<SharePartySheet
				isOpen={showShareSheet}
				onClose={() => {
					setShowShareSheet(false);
					setShowInviteFriendsSheet(true);
				}}
				party={party}
			/>
		</>
	);
}
