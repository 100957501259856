import { Profile } from "../../../types";
import { Get } from "../../fetch.wrapper";
import { routes } from "../../routes";

export default async function GetFollowing(id: string): Promise<Profile[]> {
	const data = await Get(routes.getFollowing(id));

	return data.map(
		(d: any) =>
			({
				id: d.id,
				gamertag: d.gamertag,
				name: d.name,
				profileImage: d.profile_picture,
				email: d.email,
				username: d.username,
				xp: d.xp,
			}) as Profile,
	) as Profile[];
}
