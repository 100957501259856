const errors = {
	WALLET_OPERATION_ERROR: {
		title: "Wallet Error",
		description: "An error occurred with your wallet operation.",
	},
	INSUFFICIENT_FUNDS_ERROR: {
		title: "Insufficient Funds",
		description: "You don't have enough funds to complete this operation.",
	},
	WALLET_NOT_FOUND_ERROR: {
		title: "Wallet Not Found",
		description: "We couldn't find the wallet you're looking for.",
	},
	INVALID_AMOUNT_ERROR: {
		title: "Invalid Amount",
		description: "The amount you entered is not valid for this operation.",
	},
	TRANSACTION_CREATION_ERROR: {
		title: "Transaction Error",
		description: "There was a problem creating your transaction.",
	},
	TRANSACTION_NOT_FOUND_ERROR: {
		title: "Transaction Not Found",
		description: "We couldn't find the transaction you're looking for.",
	},
	UNAUTHORIZED_WALLET_ACCESS_ERROR: {
		title: "Unauthorized Access",
		description: "You are not authorized to access this wallet.",
	},
	WALLET_LOCK_ERROR: {
		title: "Wallet Locked",
		description: "This wallet is currently locked and cannot be used.",
	},
	TRANSACTION_UPDATE_ERROR: {
		title: "Transaction Update Error",
		description: "There was a problem updating your transaction.",
	},
	UNSUPPORTED_CURRENCY_CODE_ERROR: {
		title: "Unsupported Currency",
		description: "The currency code you provided is not supported.",
	},
	UNSUPPORTED_WALLET_ITEM_TYPE_ERROR: {
		title: "Unsupported Item Type",
		description: "The wallet item type you provided is not supported.",
	},
	PARTY_DATA_ERROR: {
		title: "Party Error",
		description: "An error occurred with party data.",
	},
	PARTY_NOT_FOUND_ERROR: {
		title: "Party Not Found",
		description: "We couldn't find the party you're looking for.",
	},
	PARTY_CREATION_ERROR: {
		title: "Party Creation Error",
		description: "There was a problem creating the party.",
	},
	PARTY_UPDATE_ERROR: {
		title: "Party Update Error",
		description: "There was a problem updating the party.",
	},
	PARTICIPATION_NOT_FOUND_ERROR: {
		title: "Participation Not Found",
		description: "We couldn't find your participation in the party.",
	},
	PARTICIPATION_CREATION_ERROR: {
		title: "Participation Error",
		description: "There was a problem joining the party.",
	},
	LEADERBOARD_ENTRY_NOT_FOUND_ERROR: {
		title: "Leaderboard Entry Not Found",
		description: "We couldn't find your leaderboard entry.",
	},
	LEADERBOARD_UPDATE_ERROR: {
		title: "Leaderboard Update Error",
		description: "There was a problem updating the leaderboard.",
	},
	PARTY_BUSINESS_ERROR: {
		title: "Party Error",
		description: "An error occurred with party operations.",
	},
	INVALID_PARTY_STATUS_ERROR: {
		title: "Invalid Party Status",
		description: "The party status doesn't allow this operation.",
	},
	UNAUTHORIZED_PARTY_ACTION_ERROR: {
		title: "Unauthorized Action",
		description: "You are not authorized to perform this action on the party.",
	},
	DUPLICATE_PARTICIPATION_ERROR: {
		title: "Already Joined",
		description: "You have already joined this party.",
	},
	INVALID_BUY_IN_AMOUNT_ERROR: {
		title: "Invalid Buy-In Amount",
		description: "The buy-in amount you provided is invalid.",
	},
	PARTY_FULL_ERROR: {
		title: "Party Full",
		description:
			"The party is already full and cannot accept more participants.",
	},
	INVALID_INVITE_CODE_ERROR: {
		title: "Invalid Invite Code",
		description: "The invite code you entered is incorrect.",
	},
	MISSING_INVITE_CODE_ERROR: {
		title: "Invite Code Required",
		description: "An invite code is required to join this private party.",
	},
	NOT_PARTICIPANT_ERROR: {
		title: "Not a Participant",
		description: "You are not a participant in this party.",
	},
	INVALID_CURRENCY_TYPE_ERROR: {
		title: "Invalid Currency Type",
		description: "The currency type you selected is invalid for this party.",
	},
	INVALID_CURRENCY_CODE_ERROR: {
		title: "Invalid Currency Code",
		description: "The currency code you provided is invalid for this party.",
	},
	INVITE_CODE_NOT_AVAILABLE_ERROR: {
		title: "Invite Code Unavailable",
		description: "The invite code you provided is not available.",
	},
	INVALID_INVITE_CODE_SETTING_ERROR: {
		title: "Invalid Invite Code Setting",
		description: "Invite codes can only be set for private parties.",
	},
	UNAUTHORIZED_PUBLIC_PARTY_CREATION_ERROR: {
		title: "Unauthorized Party Creation",
		description: "You are not authorized to create a public party.",
	},
	PARTY_MISSING_IDENTIFIER_ERROR: {
		title: "Party Identifier Missing",
		description: "A party identifier is required to join a party.",
	},
	USER_OPERATION_ERROR: {
		title: "User Error",
		description: "An error occurred with user operations.",
	},
	FOLLOW_OPERATION_ERROR: {
		title: "Follow Error",
		description: "An error occurred while trying to follow the user.",
	},
	SELF_FOLLOW_ERROR: {
		title: "Cannot Follow Yourself",
		description: "You cannot follow yourself.",
	},
	USER_NOT_FOUND_ERROR: {
		title: "User Not Found",
		description: "The user you're looking for does not exist.",
	},
	ALREADY_FOLLOWING_ERROR: {
		title: "Already Following",
		description: "You are already following this user.",
	},
	NOT_FOLLOWING_ERROR: {
		title: "Not Following",
		description: "You are not following this user.",
	},
};

export default errors;
