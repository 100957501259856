import { useState } from "react";
import { useAuth } from "../../providers/authProvider";
import useTTRouter, { ttRoutes } from "../../hooks/useTTRouter";
import { App as CapacitorApp } from "@capacitor/app";
import { object, string } from "yup";
import { useAccount } from "src/providers/accountProvider";
import { Field } from "../../components/Field";
import { PrimaryButton } from "src/components/PrimaryButton";
import { useNavigate } from "react-router-dom";

const schema = object().shape({
	email: string().required().email(),
});

const getEmailError = (email: string) => {
	if (!email) {
		return "Email is required";
	}

	const isValidEmail = schema.isValidSync({ email: email ?? "" });
	if (!isValidEmail) {
		return "Please enter a valid email";
	}

	return null;
};

export default function Login() {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [email, setEmail] = useState("");
	const { otpLogin, isAuthLoading } = useAuth();
	const [emailError, setEmailError] = useState<string | null>(null);
	const navigate = useNavigate();
	const { navigateToLogin, navigateToParty } = useTTRouter();
	const { refreshAccount } = useAccount();

	const [partyRedirect, setPartyRedirect] = useState<string | null>(null);

	CapacitorApp.addListener("appUrlOpen", (data) => {
		if (data.url.includes("tictaps://")) {
			const partyCode = getUrlValueAfterParty(data.url);
			setPartyRedirect(partyCode);
		}
	});

	function getUrlValueAfterParty(url: string) {
		const keyword = "party/";
		const startIndex = url.indexOf(keyword);
		if (startIndex !== -1) {
			return url.substring(startIndex + keyword.length);
		}
		return null;
	}

	const handleLogin = () => {
		setEmailError(null);
		const error = getEmailError(email);
		if (error) {
			setEmailError(error);
			return;
		}

		setIsSubmitting(true);
		otpLogin(
			email,
			async () => {
				setIsSubmitting(false);
				refreshAccount();
				if (partyRedirect) {
					navigateToParty(partyRedirect);
				} else {
					navigate(ttRoutes.home());
				}
			},
			(message: string) => {
				setIsSubmitting(false);
				console.error(message);
				navigateToLogin();
			},
		);
	};

	return (
		<div className="w-full h-full flex flex-col items-center gap-y-8 px-5 b pt-20">
			<div className="bg-gradient-glow shadow-glow rounded-full w-32 h-32">
				<img src="/loginLogo.webp" alt="logo" className="w-full h-full" />
			</div>
			<div className="text-white text-2xl">Login</div>

			<div className="border-hairline border-[#2e3138] rounded-2xl p-4 w-full md:w-1/2 lg:w-1/3 h-fit  shadow-xl flex flex-col gap-y-4 to-[#181b21] bg-gradient-to-br from-[#31353b]">
				<Field
					label="Email"
					placeholder="Enter an email"
					value={email}
					autoCapitalize="none"
					type="email"
					autoComplete="email"
					autoCorrect="off"
					error={emailError}
					onChange={(e) => setEmail(e.target.value)}
					onBlur={(e) => {
						const error = getEmailError(e.target.value);
						setEmailError(error);
					}}
				/>

				<PrimaryButton
					disabled={isAuthLoading || isSubmitting}
					onClick={handleLogin}
				>
					{isSubmitting ? "Signing in..." : "Sign in / Register"}
				</PrimaryButton>
			</div>
		</div>
	);
}
