import { TTError } from "../providers/notificationProvider";

const base = "https://api.tictaps.dev/v1";

const getBaseUrl = (path: string) => {
	if (path.startsWith("http")) return path;
	return `${base}${path.startsWith("/") ? "" : "/"}${path}`;
};

export async function Get<T = any>(url: string): Promise<T> {
	try {
		const token = localStorage.getItem("token");
		const response = await fetch(getBaseUrl(url), {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return handleResponse(response);
	} catch (err) {
		console.error(err);
		throw err;
	}
}

export async function Patch<T = any>(path: string, data: T): Promise<any> {
	try {
		const token = localStorage.getItem("token");
		const headers: HeadersInit = {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		};

		const options: RequestInit = {
			method: "PATCH",
			headers,
			body: JSON.stringify(data),
		};

		const response = await fetch(getBaseUrl(path), options);

		if (!response.ok) {
			const errorDetails = await response.json();
			throw new Error(
				`PATCH request failed. Status: ${response.status}, Details: ${errorDetails.message || "Unknown error"}`,
			);
		}

		return response.json();
	} catch (error) {
		console.error("Error in PATCH request:", error);
		throw error;
	}
}

export async function Post<T = any>(
	path: string,
	body: T,
	specificToken?: string,
) {
	try {
		const token = localStorage.getItem("token");
		const response = await fetch(getBaseUrl(path), {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${specificToken ?? token}`,
			},
			body: JSON.stringify(body),
		});
		return handleResponse(response);
	} catch (err) {
		console.error(err);
		throw err;
	}
}

export async function Delete<T = any>(path: string, body?: T) {
	try {
		const token = localStorage.getItem("token");
		const response = await fetch(getBaseUrl(path), {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: body ? JSON.stringify(body) : undefined,
		});
		return handleResponse(response);
	} catch (err) {
		console.error(err);
		throw err;
	}
}

async function handleResponse(response: Response) {
	const data = await response.json();

	if (response.ok) {
		return data.body;
	}

	if (response.status === 401) throw TTError("401", "401", "401");

	const description = Object.keys(data).includes("message")
		? data.message
		: undefined;

	const error_code = Object.keys(data).includes("error_code")
		? data.error_code
		: undefined;

	if (description && error_code) {
		throw TTError(error_code, "Error", description);
	}

	throw TTError(
		"Undocumented Error",
		"Server Error",
		`${response.status} ${response.statusText}`,
	);
}
