import type { PartyHistoryEntry } from "src/types";

export const groupByDate = (data: PartyHistoryEntry[]) => {
	const groupedData = data.reduce<Record<string, PartyHistoryEntry[]>>(
		(accumulator, current) => {
			const date = current.date;
			if (!accumulator[date]) {
				accumulator[date] = [];
			}
			accumulator[date].push(current);
			return accumulator;
		},
		{},
	);

	return Object.keys(groupedData)
		.sort()
		.map((date) => ({
			date,
			entries: groupedData[date].sort((a, b) => b.date.localeCompare(a.date)),
		}));
};
