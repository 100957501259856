"use client";
import { AppScreen } from "./AppScreen";
import main from "./images/mainpage.png";

export function AppDemo() {
	return (
		<AppScreen>
			<img src={main} alt="" />
		</AppScreen>
	);
}
