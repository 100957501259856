/**
 * Utility function to preload Unity WebGL game files
 * @param baseUrl The base URL of the Unity build folder
 * @returns Promise that resolves when all assets are preloaded
 */
export const preloadAsset = (baseUrl: string) => {
	const url = baseUrl?.slice(0, baseUrl.lastIndexOf("/"));

	const filesToPreload = [
		`${url}/Build/game.loader.js`,
		`${url}/Build/game.data`,
		`${url}/Build/game.framework.js`,
		`${url}/Build/game.wasm`,
	];

	const preloadPromises = filesToPreload.map(
		(url) =>
			new Promise<void>((resolve, reject) => {
				fetch(url, { method: "GET", mode: "cors", cache: "force-cache" })
					.then((response) => {
						if (!response.ok) {
							throw new Error(
								`Failed to preload asset: ${response.status} ${response.statusText}`,
							);
						}
						resolve();
					})
					.catch((error) => {
						console.error(`Error preloading asset from ${url}:`, error);
						reject(error);
					});
			}),
	);

	return Promise.all(preloadPromises);
};
