import { Game, Party } from "../../types";
import { Get } from "../fetch.wrapper";
import { routes } from "../routes";
import { DateTime } from "luxon";

export enum PartyStatus {
	ACTIVE = "ACTIVE",
	CLOSED = "CLOSED",
	CANCELLED = "CANCELLED",
}
export default async function GetParty(id: string): Promise<Party> {
	const data = await Get(routes.party(id));
	const createdDateTime = DateTime.fromISO(data.created_date, {
		zone: "UTC",
	}).setZone("Africa/Johannesburg");
	const endDateTime = DateTime.fromISO(data.end_date_time, {
		zone: "UTC",
	}).setZone("Africa/Johannesburg");

	return {
		id: data.id,
		currencyType: data.currency_type,
		currencyCode: data.currency_code,
		isPublic: !data.is_private,
		inviteCode: data.invite_code,
		name: data.name,
		participants: data.participants,
		buyInAmount: Number(data.buy_in_amount),
		createdById: data.created_by_id,
		createdDate: createdDateTime.toJSDate(),
		endDate: endDateTime.toJSDate(),
		chatChannelId: data.chat_channel_id,
		status: data.status,
		game: {
			id: data.game.id,
			description: data.game.description,
			name: data.game.name,
			icon: data.game.icon_url,
			gameplayPreview: data.game.preview_url,
			gamePreview: data.game.banner_url,
			gameUrl: data.game.game_url,
		} as Game,
	};
}
