import { Outlet } from "react-router-dom";
import { LayoutProvider } from "./providers/layoutProvider";
import { PartiesProvider } from "./providers/partiesProvider";
import { useAccount } from "./providers/accountProvider";
import { LoadingOverlay } from "./components/LoadingOverlay";

export default function App() {
	const { account, isAccountLoading } = useAccount();

	if (isAccountLoading) {
		return <LoadingOverlay description="Everybody plays. Everybody wins" />;
	}

	if (!isAccountLoading && !account) {
		return <LoadingOverlay description="Everybody plays. Everybody wins" />;
	}

	return (
		<PartiesProvider>
			<LayoutProvider>
				<Outlet />
			</LayoutProvider>
		</PartiesProvider>
	);
}
