import { Post } from "../../fetch.wrapper";
import { routes } from "../../routes";

export default async function AddTrophies(amount: number) {
	await Post(routes.fundWallet(), {
		amount,
		currency_type: "AWARD",
		currency_code: "TROPHIES",
	});

	return true;
}
