import { currencies } from "../../../providers/accountProvider";
import { Get } from "../../fetch.wrapper";
import { routes } from "../../routes";

interface BalanceItem {
	currency_type: string;
	currency_code: string;
	balance_type: string;
	balance: number;
	state: string;
}

function updateCurrencies(
	data: { currency_type: string; currency_code: string; balance: number }[],
) {
	const balances = currencies.map((c) => ({ ...c, amount: 0 }));

	return balances.map((currency) => {
		const found = data.find(
			(c) =>
				c.currency_type === currency.type && c.currency_code === currency.code,
		);
		if (found) {
			return {
				...currency,
				amount: found.balance,
			};
		}
		return currency;
	});
}

function sumBalances(data: BalanceItem[]): any[] {
	const result: { [key: string]: any } = {};

	data.forEach((item) => {
		const key = `${item.currency_type}-${item.currency_code}`;

		if (!result[key]) {
			result[key] = {
				currency_type: item.currency_type,
				currency_code: item.currency_code,
				balance_type: item.balance_type,
				balance: 0,
			};
		}

		result[key].balance += Number(item.balance);
	});

	return Object.values(result);
}

export default async function GetBalances() {
	const response: BalanceItem[] = await Get(routes.walletBalance());
	const availableBalances = response.filter((c) => c.state === "AVAILABLE");
	const summedBalances = sumBalances(availableBalances);
	return updateCurrencies(summedBalances);
}
