import BubblesBackground from "./BubblesBackground";
import { Container } from "./Container";
import { InstallButton } from "./InstallButton";
import { Logo } from "./Logo";
import NavLinks from "./NavLinks";

export function Footer() {
	return (
		<footer className="bg-tic-taps-grey z-[40] overflow-hidden">
			<Container>
				<div className="relative flex flex-col items-start justify-between gap-y-12 pb-6 pt-16 lg:flex-row lg:items-center lg:py-16">
					<div className="absolute left-1/2 top-20 transform -translate-x-1/2 w-full opacity-75 scale-x-100 blur-3xl z-0">
						<BubblesBackground />
					</div>

					<div className="flex flex-col sm:flex-row items-center justify-between text-white w-full gap-6">
						<div className="flex flex-col sm:flex-row items-center gap-4">
							<div className="flex items-center">
								<Logo />
								<div className="ml-4">
									<p className="text-base font-semibold">TicTaps</p>
								</div>
							</div>

							<nav className="flex gap-6 mt-6 sm:mt-0 sm:ml-8">
								<NavLinks />
							</nav>
						</div>

						<div className="flex items-center z-40 mt-4 sm:mt-0">
							<InstallButton />
						</div>
					</div>
				</div>
				<div className="flex items-center border-t border-gray-200 pt-8 justify-center md:pt-6">
					<p className="mt-2 pb-6 text-sm text-gray-200 text-center">
						Tictaps B.V. | Reg. No: 93585837 | VAT: NL866459182B01 | Contact:
						info@tictaps.com
						<br />
						<span className="block mt-3">
							&copy; Copyright {new Date().getFullYear()}. All rights reserved.
						</span>
					</p>
				</div>
			</Container>
		</footer>
	);
}
