import type { Party } from "../types";
import { GradientBorder } from "./GradientContainer";
import { useEffect, useState } from "react";
import { PartyStatus } from "../api/party/getParty";
import { LoadingSpinner } from "./LoadingOverlay";
import { ClosePartyModal } from "./ClosePartyModal";
import { ParticipationControls } from "./ParticipationControls";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { TutorialSheet } from "src/pages/app/Party/TutorialSheet";
import { preloadAsset } from "src/utils/preload-asset";

function FormatTimeLeft(endDate: Date): {
	hasEnded: boolean;
	timeLeft: string;
} {
	const currentTime = new Date();
	const timeDifferenceInMs = endDate.getTime() - currentTime.getTime();

	const hoursLeft = Math.floor(timeDifferenceInMs / (1000 * 60 * 60));
	const minutesLeft = Math.floor(
		(timeDifferenceInMs % (1000 * 60 * 60)) / (1000 * 60),
	);

	return {
		hasEnded: timeDifferenceInMs <= 0,
		timeLeft: `${hoursLeft}h ${minutesLeft}m`,
	};
}

function calculateTimeProgress(createdDate: Date, endDate: Date): number {
	const currentTime = new Date().getTime();
	const createdTime = createdDate.getTime();
	const endTime = endDate.getTime();

	const totalDuration = endTime - createdTime;
	const elapsedTime = currentTime - createdTime;

	if (currentTime >= endTime) {
		return 100;
	}

	if (currentTime <= createdTime) {
		return 0;
	}

	return (elapsedTime / totalDuration) * 100;
}

export default function PartyControls({
	endParty,
	isOwner,
	isParticipant,
	party,
	startGame,
	joinParty,
	leaveParty,
	isLoading,
	hasPlayedAlready,
}: Readonly<{
	party: Party;
	isOwner: boolean;
	isParticipant: boolean;
	joinParty: (party: Party) => void;
	leaveParty: (party: Party) => void;
	startGame: (party: Party) => void;
	endParty: (party: Party) => void;
	hasPlayedAlready: boolean;
	isLoading?: boolean;
}>) {
	const [isClosePartVisible, setIsClosePartyVisible] = useState(false);
	const [isSheetOpen, setSheetOpen] = useState(false);

	const tutorialData = {
		title: party.game.name,
		description: party.game.description,
		picture: party.game.gameplayPreview,
	};
	const { hasEnded, timeLeft } = FormatTimeLeft(party.endDate);
	const isPartyActive = party.status === PartyStatus.ACTIVE;
	const progress = calculateTimeProgress(party.createdDate, party.endDate);
	const totalPot = party.buyInAmount * (party.participants || 0);

	const isGameDisabled =
		(!isParticipant && !isOwner) ||
		!isPartyActive ||
		hasEnded ||
		hasPlayedAlready;

	useEffect(() => {
		if (!isGameDisabled && party.game.gameUrl) {
			preloadAsset(party.game.gameUrl);
		}
	}, [isGameDisabled, party.game.gameUrl]);

	return (
		<>
			<div className="flex flex-col space-y-2">
				<button
					type="button"
					onClick={() => startGame(party)}
					disabled={isGameDisabled}
					className={`text-white h-12 font-semibold w-full rounded-xl px-2 py-1 mb-2 ${
						hasEnded
							? "opacity-80 bg-gradient-to-b from-gray-500 to-gray-600"
							: "bg-gradient-to-br from-[#00FFAA] via-[#4579F5] to-[#9C42F5] shadow-glow disabled:opacity-30"
					}`}
				>
					{isLoading ? (
						<div className="flex items-center justify-center py-1">
							<LoadingSpinner />
						</div>
					) : (
						"Start"
					)}
				</button>

				<div className="flex justify-between space-x-2">
					<button
						type="button"
						onClick={() => setSheetOpen(true)}
						className="relative shadow-lg text-gray-200 h-14 font-semibold bg-tic-taps-grey border-hairline border-zinc-700 w-1/3 rounded-xl flex flex-col items-center justify-center py-2 px-1 cursor-pointer hover:shadow-xl transition-shadow"
					>
						<p className="text-xs font-medium text-gray-400">Game</p>
						<p className="text-sm font-semibold text-gray-100">
							{party.game.name}
						</p>

						<InformationCircleIcon className="top-1 animate-pulse right-1 absolute size-6" />
					</button>
					<div
						className={`shadow-lg text-gray-200 h-14 font-semibold bg-tic-taps-grey ${
							hasEnded ? "border-zinc-700" : "border-yellow-600"
						} border-hairline w-1/3 rounded-xl flex flex-col items-center justify-center p-2`}
					>
						<p
							className={`text-xs font-medium ${
								hasEnded ? "text-gray-200" : "text-gray-300"
							}`}
						>
							Total Pot
						</p>
						{party.currencyType === "FIAT" ? (
							<div className="flex items-center space-x-1">
								<img src="/icons/coin.webp" alt="coin icon" className="w-4" />
								<p
									className={`text-base font-semibold ${
										hasEnded ? "text-gray-200" : "text-yellow-500"
									}`}
								>
									{totalPot}
								</p>
							</div>
						) : (
							<div className="flex items-center space-x-1">
								<img
									src="/icons/ttoken.webp"
									alt="token icon"
									className="w-4"
								/>
								<p
									className={`text-base font-semibold ${
										hasEnded ? "text-gray-200" : "text-yellow-500"
									}`}
								>
									{totalPot}
								</p>
							</div>
						)}
					</div>

					<div
						className={`shadow-lg text-gray-200 h-14 font-semibold bg-tic-taps-grey ${
							hasEnded ? "border-zinc-700" : "border-green-700"
						} border-hairline w-1/3 rounded-xl flex flex-col items-center justify-center p-2`}
					>
						<p
							className={`text-xs font-medium ${
								hasEnded ? "text-gray-200" : "text-gray-300"
							}`}
						>
							Buy In
						</p>
						{party.currencyType === "FIAT" ? (
							<div className="flex items-center space-x-1">
								<img src="/icons/coin.webp" alt="coin icon" className="w-4" />
								<p
									className={`text-base font-semibold ${
										hasEnded ? "text-gray-200" : "text-green-100"
									}`}
								>
									{party.buyInAmount}
								</p>
							</div>
						) : (
							<div className="flex items-center space-x-1">
								<img
									src="/icons/ttoken.webp"
									alt="token icon"
									className="w-4"
								/>
								<p
									className={`text-base font-semibold ${
										hasEnded ? "text-gray-200" : "text-green-100"
									}`}
								>
									{party.buyInAmount}
								</p>
							</div>
						)}
					</div>
				</div>

				<div className="flex w-full space-x-2">
					<ParticipationControls
						party={party}
						hasEnded={hasEnded}
						isOwner={isOwner}
						isParticipant={isParticipant}
						isPartyActive={isPartyActive}
						onPartyJoin={joinParty}
						onLeaveParty={leaveParty}
						isLoading={isLoading}
					/>

					<button
						type="button"
						disabled={!isPartyActive || hasEnded}
						onClick={() => {
							if (isOwner) setIsClosePartyVisible(true);
						}}
						className="w-full"
					>
						<GradientBorder
							disabled={!isPartyActive || hasEnded}
							from="#FF5E00"
							via={["#F54562"]}
							to={"#C32DE1"}
						>
							<div className="bg-tic-taps-secondary-grey px-4 py-1 w-full">
								{hasEnded && (
									<>
										<p className="text-gray-300 text-sm self-center">Party</p>
										<p className=" text-gray-100 text-sm self-center">Ended</p>
									</>
								)}
								{!hasEnded && (
									<>
										<p className="text-gray-300 text-sm self-center">Ends in</p>
										<p className="text-gray-300 text-lg self-center">
											{timeLeft}
										</p>
									</>
								)}
								{!hasEnded && (
									<div className="w-full -mb-1">
										<TimeToEnd progress={progress} />
									</div>
								)}
							</div>
						</GradientBorder>
					</button>
				</div>
			</div>

			<ClosePartyModal
				open={isClosePartVisible}
				onClose={() => setIsClosePartyVisible(false)}
				onConfirm={() => {
					setIsClosePartyVisible(false);
					endParty(party);
				}}
			/>

			<TutorialSheet
				isOpen={isSheetOpen}
				onClose={() => setSheetOpen(false)}
				title={tutorialData.title}
				description={tutorialData.description}
				picture={tutorialData.picture}
			/>
		</>
	);
}

function TimeToEnd({ progress }: { progress: number }) {
	return (
		<div className="w-full bg-gray-700 rounded-full h-1">
			<div
				className="h-1 rounded-full bg-gradient-to-r from-[#FF5E00] via-[#F54562] to-[#C32DE1]"
				style={{ width: `${progress}%` }}
			/>
		</div>
	);
}
