import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";

type IosInstallProps = {
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function IosInstall({ setIsOpen }: IosInstallProps) {
	return (
		<div className=" bg-zinc-800 p-4 border-t border-cyan-600">
			<div className="flex">
				<div className="shrink-0">
					<InformationCircleIcon
						aria-hidden="true"
						className="h-5 w-5 text-cyan-400"
					/>
				</div>
				<div className="ml-3 flex-1 md:flex md:justify-between">
					<p className="text-md text-gray-200">
						To install this app on your iOS device, tap the{" "}
						<span className="inline-flex items-center bg-zinc-900 px-1 py-1 rounded text-cyan-400 font-medium">
							<ArrowUpOnSquareIcon className="h-4 w-6" />
						</span>{" "}
						button, and then select "Add to Home Screen."
					</p>
					<p className="mt-3 text-sm md:ml-6 md:mt-0">
						<button
							type="button"
							onClick={() => setIsOpen(false)}
							className="whitespace-nowrap font-medium text-cyan-500 hover:text-cyan-300"
						>
							Maybe Later
							<span aria-hidden="true"> &rarr;</span>
						</button>
					</p>
				</div>
			</div>
		</div>
	);
}
