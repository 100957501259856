import { Post } from "../../fetch.wrapper";
import { routes } from "../../routes";

export default async function AddTicTapsTokens(amount: number) {
	await Post(routes.fundWallet(), {
		amount,
		currency_type: "TICTAPS",
		currency_code: "TTT",
	});

	return true;
}
