import { Navigate } from "react-router-dom";
import { useAuth } from "../providers/authProvider";
import { ttRoutes } from "../hooks/useTTRouter";

export default function ProtectedRoute({
	children,
}: {
	children: JSX.Element;
}): JSX.Element {
	const { isLoggedIn } = useAuth();
	const isEnabled = process.env.REACT_APP_DOWNLOAD_ENABLED !== "false";

	if (!isEnabled) {
		return <Navigate to={"/about"} replace />;
	}

	if (!isLoggedIn) {
		return <Navigate to={ttRoutes.login()} replace />;
	}

	return children;
}
