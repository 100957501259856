import type { ComponentPropsWithoutRef } from "react";
import { cn } from "src/utils/cn";

import styles from "./index.module.css";

export const PrimaryButton = ({
	children,
	className,
	...props
}: ComponentPropsWithoutRef<"button">) => {
	return (
		<button type="button" className={cn(styles.button, className)} {...props}>
			{children}
		</button>
	);
};
