import { LeaderboardEntry } from "src/api/types";
import type { Participant } from "../../../types";
import { Get } from "../../fetch.wrapper";
import { routes } from "../../routes";

export default async function GetLeaderboard(
	partyId: string,
): Promise<Participant[]> {
	const data = await Get<LeaderboardEntry[]>(routes.partyLeaderboard(partyId));
	const res = data.map(
		(e) =>
			({
				profile: {
					id: e.user_id,
					username: e.username,
					gamertag: e.gamer_tag,
					profileImage: e.profile_image,
					email: e.email_address,
				},
				buyIn: 0,
				highSchore: e.score,
			}) as Participant,
	) as Participant[];
	return res;
}
