export default function SeeAll({ onClick }: { onClick: () => void }) {
	return (
		<button
			type="button"
			onClick={() => onClick()}
			className="text-sm font-semibold text-transparent bg-clip-text text-white"
		>
			See all
		</button>
	);
}
