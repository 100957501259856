import GetGames from "../api/games/getGames";
import { useQuery } from "@tanstack/react-query";

export default function useGames() {
	const games = useQuery({
		queryKey: ["games"],
		queryFn: () => GetGames(),
	});

	return {
		data: games.data ?? [],
		isLoading: games.isLoading,
		refetch: () => games.refetch(),
	};
}
