import { createContext, useContext, type ReactNode } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAccount } from "./accountProvider";
import { TTError } from "./notificationProvider";
import GetFollowers from "../api/account/follow/getFollowers";
import GetUser from "../api/account/profile/getUser";
import type { Profile, ProfileHistory } from "../types";
import GetIsFollowing from "../api/account/follow/getIsFollowing";
import GetFollowing from "../api/account/follow/getFollowedUsers";
import GetPartiesHistory from "../api/account/profile/getHistory";

const ProfileContext = createContext<
	| {
			profile: any | undefined;
			isProfileLoading: boolean;

			followers: Profile[] | undefined;
			isFollowersLoading: boolean;

			following: Profile[] | undefined;
			isFollowingLoading: boolean;

			isBeingFollowed: boolean | undefined;
			isBeingFollowedLoading: boolean;

			history: ProfileHistory[] | undefined;
			isHistoryLoading: boolean;

			refreshProfile: () => void;
	  }
	| undefined
>(undefined);

export function ProfileProvider({
	children,
	userId,
}: Readonly<{
	children: ReactNode;
	userId: string;
}>) {
	const { account } = useAccount();
	const qClient = useQueryClient();

	const profileQuery = useQuery({
		queryKey: ["profile", userId],
		queryFn: async () => {
			return await GetUser(userId);
		},
	});

	const followersQuery = useQuery({
		queryKey: ["profile_followers", userId],
		queryFn: async () => {
			return await GetFollowers(userId);
		},
	});

	const historyQuery = useQuery({
		queryKey: ["profile_history", userId],
		queryFn: async () => {
			if (!account) {
				throw TTError("", "Error", "Account not found");
			}
			const data = await GetPartiesHistory();
			return data;
		},
	});

	const followingQuery = useQuery({
		queryKey: ["profile_following", userId],
		queryFn: async () => {
			return await GetFollowing(userId);
		},
	});

	const isBeingFollowed = useQuery({
		queryKey: ["profile_isBeingFollowed", userId],
		queryFn: async () => {
			if (!account) {
				throw TTError("", "Error", "Account not found");
			}
			return await GetIsFollowing(userId, account.id);
		},
	});

	function refreshProfile() {
		qClient.refetchQueries({
			queryKey: ["profile", userId],
		});
		qClient.refetchQueries({
			queryKey: ["profile_followers", userId],
		});
		qClient.refetchQueries({
			queryKey: ["profile_following", userId],
		});
		qClient.refetchQueries({
			queryKey: ["profile_isBeingFollowed", userId],
		});
		qClient.refetchQueries({
			queryKey: ["profile_history", userId],
		});
	}

	return (
		<ProfileContext.Provider
			value={{
				refreshProfile,

				profile: profileQuery.data,
				isProfileLoading: profileQuery.isLoading,

				followers: followersQuery.data,
				isFollowersLoading:
					followersQuery.isLoading || followersQuery.isFetching,

				isBeingFollowed: isBeingFollowed.data,
				isBeingFollowedLoading:
					isBeingFollowed.isLoading || isBeingFollowed.isFetching,

				following: followingQuery.data,
				isFollowingLoading:
					followingQuery.isLoading || followingQuery.isFetching,

				history: historyQuery.data ? [historyQuery.data] : undefined,
				isHistoryLoading: historyQuery.isLoading,
			}}
		>
			{children}
		</ProfileContext.Provider>
	);
}

export function useProfile() {
	const context = useContext(ProfileContext);
	if (context === undefined) {
		throw new Error("usProfile must be used within a ProfileProvider");
	}
	return context;
}
