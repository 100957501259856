import frame from "../website/images/phone_frame_crop.png";
import { cn } from "src/utils/cn";

export function PhoneFrame({
	className,
	children,
	priority = false,
	...props
}: React.ComponentPropsWithoutRef<"div"> & { priority?: boolean }) {
	return (
		<div className={cn("relative aspect-[366/729]", className)} {...props}>
			<div className="absolute inset-0 flex items-center rounded-3xl justify-center overflow-hidden shadow-2xl">
				<div className="relative h-[95%] w-[88%] overflow-hidden">
					{children}
				</div>
			</div>
			<img
				src={frame}
				alt=""
				className="pointer-events-none absolute inset-0 h-full w-full"
			/>
		</div>
	);
}
