import type { ReactNode, CSSProperties } from "react";

interface SheenProps {
	children: ReactNode;
	className?: string;
	speed?: number; // seconds
}

const Sheen = ({ children, speed = 1.5 }: SheenProps) => {
	return (
		<div
			className="relative overflow-hidden"
			style={{ "--sheen-speed": `${speed}s` } as CSSProperties}
		>
			{children}
			<div className="absolute inset-0 before:absolute before:inset-0 before:bg-gradient-to-r before:from-transparent before:via-white/60 before:to-transparent before:w-[150%] before:h-full before:translate-x-[-100%] before:animate-sheen" />
		</div>
	);
};

export default Sheen;
