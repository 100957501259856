import useTTRouter, { ttRoutes } from "src/hooks/useTTRouter";
import { useAccount } from "src/providers/accountProvider";
import {
	ChartBarIcon,
	HomeIcon,
	PlusCircleIcon,
	UserGroupIcon,
	WalletIcon,
} from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";
import { Sheet } from "react-modal-sheet";
import { useState } from "react";
import { NotificationsMenuItem } from "./NotificationsMenuItem";

export function Footer() {
	const { account, balance } = useAccount();
	const { navigateToOnboardMoney } = useTTRouter();

	const [balanceOverview, setBalanceOverview] = useState(false);

	return (
		<>
			<Sheet
				detent="content-height"
				isOpen={balanceOverview}
				onClose={() => setBalanceOverview(false)}
			>
				<Sheet.Container className="h-2/5">
					<Sheet.Header className="bg-tic-taps-grey" />
					<Sheet.Content className="bg-tic-taps-grey pb-10 px-2">
						<p className="text-white font-semibold text-xl">Balance</p>
						<div className="items-center gap-y-2 h-full overflow-y-scroll">
							<div className="flex flex-row p-3 px-6 my-1 rounded-2xl bg-tic-taps-secondary-grey shadow-md w-full gap-x-2 items-center">
								<img src="/icons/ttoken.webp" alt="" className="w-6" />
								<p className="text-white text-lg font-bold">
									{balance.ticTapsTokens}
								</p>
							</div>
							<div className="flex flex-row p-3 px-6 my-1 rounded-2xl bg-tic-taps-secondary-grey shadow-md w-full gap-x-2 items-center">
								<img src="/icons/coin.webp" alt="" className="w-6" />
								<p className="text-white text-lg font-bold">
									{balance.dollars}
								</p>
							</div>
							<div className="flex flex-row p-3 px-6 my-1 rounded-2xl bg-tic-taps-secondary-grey shadow-md w-full gap-x-2 items-center">
								<img src="/icons/trophy.webp" alt="" className="w-6" />
								<p className="text-white text-lg font-bold">
									{balance.trophies}
								</p>
							</div>
						</div>
						<div className="w-full flex justify-center p-2">
							<button
								type="button"
								onClick={() => {
									setBalanceOverview(false);
									navigateToOnboardMoney();
								}}
								className="max-w-48 bg-white w-full rounded-lg px-1 py-2"
							>
								<p className="text-gray-900 text-lg font-semibold">Deposit</p>
							</button>
						</div>
					</Sheet.Content>
				</Sheet.Container>
				<Sheet.Backdrop className="bg-tic-taps-grey" />
			</Sheet>

			<div className="bg-tic-taps-grey w-full pb-10 pt-4 border-t-hairline border-neutral-700 relative z-[50]">
				<div className="w-full md:w-2/3 lg:w-1/2 mx-auto flex justify-between items-center px-6">
					<NavLink
						to={ttRoutes.home()}
						className={({ isActive }) =>
							isActive ? "text-cyan-500" : "text-white"
						}
					>
						<HomeIcon className="size-8  transition-all duration-100 ease-in-out" />
					</NavLink>

					<NavLink
						to={ttRoutes.friends()}
						className={({ isActive }) =>
							isActive ? "text-cyan-500" : "text-white"
						}
					>
						<UserGroupIcon className="size-8 transition-all duration-100 ease-in-out" />
					</NavLink>

					<NavLink
						to={ttRoutes.createParty()}
						className={({ isActive }) =>
							isActive ? "text-cyan-500" : "text-white"
						}
					>
						<PlusCircleIcon className="size-8 transition-all duration-100 ease-in-out" />
					</NavLink>

					<NavLink
						to={ttRoutes.userHistory(account?.id)}
						className={({ isActive }) =>
							isActive ? "text-cyan-500" : "text-white"
						}
					>
						<ChartBarIcon className="size-8 transition-all duration-100 ease-in-out" />
					</NavLink>

					<NavLink
						to={"#"}
						onClick={() => setBalanceOverview(true)}
						className={() => (balanceOverview ? "text-cyan-500" : "text-white")}
					>
						<WalletIcon className="size-8 transition-all duration-100 ease-in-out" />
					</NavLink>

					<NotificationsMenuItem />
				</div>
			</div>
		</>
	);
}
