import { useNavigate } from "react-router-dom";
import { UserProfileComponent } from "src/components/UserProfileComponent";
import { useImageBackground } from "src/providers/layoutProvider";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useAccount } from "src/providers/accountProvider";
import PullToRefresh from "react-simple-pull-to-refresh";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import useTTRouter from "src/hooks/useTTRouter";
import { GradientBorder } from "src/components/GradientContainer";
import { FullScreenLoadingOverlay } from "src/components/LoadingOverlay";
import PartyHistoryCard from "src/components/PartyHistoryCard";

const defaultProfileImage =
	"https://preview.redd.it/new-profile-pictures-for-yall-v0-brdjms2xte3c1.jpg?width=720&format=pjpg&auto=webp&s=ee4dd7a6b958c218987219c7ba5311424d2a3345";

export function AccountPage() {
	const {
		account,
		profileHistory,
		isAccountLoading,
		balance,
		refreshAccount,
		refreshBalances,
	} = useAccount();

	const navigate = useNavigate();
	const { navigateToSettings } = useTTRouter();
	useImageBackground(account?.profileImage ?? defaultProfileImage);

	function handleBackClick() {
		navigate(-1);
	}

	function handleRefresh() {
		refreshAccount();
		refreshBalances();
	}

	const parties = profileHistory?.parties;

	return (
		<div className="h-full overflow-y-auto">
			<FullScreenLoadingOverlay
				display={isAccountLoading}
				description={"Loading profile..."}
			/>
			<PullToRefresh
				onRefresh={async () => handleRefresh()}
				pullingContent="Nosey fucker, arent you."
			>
				<>
					<div className="flex flex-col justify-center space-y-4 relative z-[30]">
						<button
							type="button"
							aria-label="Back"
							onClick={handleBackClick}
							className="absolute z-[50] top-4 px-2 left-4 mt-1 bg-transparent text-white rounded-full hover:bg-gray-600"
						>
							<ChevronLeftIcon className="h-8 w-8" />
						</button>
						<UserProfileComponent
							profile={{
								profileImage: account?.profileImage,
								gamertag: account?.gamerTag,
							}}
							winRatio={profileHistory?.winRatio || 0}
						/>
						<div className="absolute z-[80] top-1 right-8 text-white rounded-xl">
							<GradientBorder padding="p-0.5" rounded="rounded-lg">
								<button
									type="button"
									aria-label="Settings"
									onClick={navigateToSettings}
									className="flex items-center justify-center bg-transparent text-cyan-500 p-2 rounded-xl text-sm font-semibold shadow-md"
								>
									<FontAwesomeIcon icon={faCog} />
								</button>
							</GradientBorder>
						</div>
					</div>

					<div className="flex flex-col gap-y-2 relative z-[10] mb-60 pt-2">
						{parties?.length === 0 ? (
							<div className="flex justify-center items-center h-full pt-16">
								<p className="text-lg text-white font-light">
									No games played yet 😔
								</p>
							</div>
						) : (
							<div className="flex flex-col space-y-1 px-2">
								<h3 className="text-md text-white font-semibold ">
									Game History
								</h3>
								<div className="flex flex-row w-full justify-between backdrop-blur rounded-lg">
									<div className="flex overflow-x-scroll space-x-2 pt-2 px-2 pb-2">
										{parties?.map((entry) => (
											<PartyHistoryCard
												key={entry.id}
												game={entry.game}
												party={entry}
											/>
										))}
									</div>
								</div>
							</div>
						)}
					</div>
				</>
			</PullToRefresh>
		</div>
	);
}
