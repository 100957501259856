import type { Friend } from "src/api/types";

import styles from "./index.module.css";
import { RowActionButton } from "src/components/RowActionButton";
import { useState } from "react";

export const FriendInviteRow = ({
	friend,
	isParticipant,
	onInvite,
	onCancel,
}: {
	friend: Friend;
	isParticipant: boolean;
	onInvite: (friend: Friend) => Promise<void>;
	onCancel: (friend: Friend) => Promise<void>;
}) => {
	const [isLoading, setIsLoading] = useState(false);

	const handleInvite = async () => {
		setIsLoading(true);
		await onInvite(friend);
		setIsLoading(false);
	};

	const handleCancel = async () => {
		setIsLoading(true);
		await onCancel(friend);
		setIsLoading(false);
	};

	return (
		<li className={styles.row}>
			<div className={styles.content}>
				<img
					alt=""
					src={friend.profile_image ?? ""}
					width={40}
					height={40}
					className={styles.avatar}
				/>
				<div>
					<p className={styles.name}>{friend.gamer_tag}</p>
				</div>
			</div>
			{isParticipant ? (
				<RowActionButton
					variant="secondary"
					onClick={handleCancel}
					disabled={isLoading}
				>
					Cancel
				</RowActionButton>
			) : (
				<RowActionButton onClick={handleInvite} disabled={isLoading}>
					Invite
				</RowActionButton>
			)}
		</li>
	);
};
