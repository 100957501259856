import { TrophyIcon } from "@heroicons/react/24/solid";
import type { Participant } from "../types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { GradientBorder } from "./GradientContainer";
import { LoadingSpinner } from "./LoadingOverlay";

export default function ScoresList({
	participants,
	isParticipantsLoading,
	seeParticipantsProfile,
}: Readonly<{
	participants: Participant[];
	isParticipantsLoading: boolean;
	seeParticipantsProfile: (participant: Participant) => void;
}>) {
	return (
		<div className="flex flex-col gap-x-2 h-84">
			{isParticipantsLoading && <LoadingSpinner />}

			{!isParticipantsLoading && participants.length === 0 && (
				<div className="flex flex-col pt-2 space-y-2 w-full px-2">
					<p className="font-light text-gray-100 text-center">
						What a lonely leaderboard 😭
					</p>
					{Array.from({ length: 5 }).map((_, index) => (
						<div
							key={index}
							className="bg-[#1f2328] rounded-3xl opacity-80 p-5 border border-[#333a42] flex items-center justify-between shadow-lg"
						>
							<div className="relative flex items-center space-x-4 animate-pulse">
								<div className="flex items-center space-x-2">
									<div className="relative flex items-center">
										<div className="h-6 w-6 bg-zinc-700 rounded-full" />
									</div>
									<div className="rounded-full h-12 w-12 bg-zinc-600" />
									<div className="flex flex-col space-y-1">
										<div className="h-4 w-24 bg-zinc-700 rounded" />
										<div className="h-4 w-16 bg-zinc-600 rounded" />
									</div>
								</div>
							</div>

							<div className="flex items-center space-x-1 animate-pulse">
								<div className="flex flex-col items-center">
									<div className="h-6 w-6 bg-zinc-700 rounded" />
								</div>
							</div>
						</div>
					))}
				</div>
			)}

			<div className="flex flex-col pt-2 space-y-2 w-full px-2">
				{participants.map((participant, index) => (
					<button
						type="button"
						key={participant.profile.id}
						onClick={() => seeParticipantsProfile(participant)}
						className="bg-[#1f2328] rounded-3xl p-5 border border-[#333a42] flex items-center justify-between shadow-lg hover:shadow-xl transition-all duration-300"
					>
						<div className="relative flex items-center space-x-4">
							<div className="flex items-center space-x-2">
								<div className="relative flex items-center">
									<FontAwesomeIcon
										opacity={0.7}
										color="#FFD700"
										size="xl"
										icon={faStar}
										className="transition-all duration-300 transform hover:scale-110"
									/>
									<span className="absolute mt-0.5 inset-0 flex items-center justify-center text-black text-xs font-extrabold">
										{index + 1}
									</span>
								</div>
								<GradientBorder padding="p-0.5" rounded="rounded-full">
									<img
										src={participant.profile.profileImage}
										alt={`${participant.profile.gamertag} Profile`}
										className="rounded-full h-12 w-12 object-cover transform transition-transform duration-300 hover:scale-105"
									/>
								</GradientBorder>
								<div className="flex flex-col items-start space-y-1">
									<p className="text-white pl-1 truncate max-w-40 font-semibold text-lg tracking-wide">
										{participant.profile.gamertag}
									</p>
									<p className="flex rounded-full h-4 w-16 text-left text-xs text-gray-300 font-medium">
										<TrophyIcon className="w-6 h-4 text-orange-500" />
										<div className=" flex items-center text-lg text-orange-500 font-bold">
											{participant.profile.xp}
										</div>
									</p>
								</div>
							</div>
						</div>

						<div className="flex items-center space-x-1">
							<div className="flex flex-col items-center">
								<div className=" flex items-center justify-center text-lg text-yellow-500 font-bold">
									{participant.highSchore}
								</div>
							</div>
						</div>
					</button>
				))}
			</div>
		</div>
	);
}
