import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Post } from "src/api/fetch.wrapper";
import type { Notification } from "src/api/types";

export const useNotification = () => {
	const queryClient = useQueryClient();

	const markAsReadMutation = useMutation({
		mutationFn: async (notification: Notification) => {
			return Post(`/notifications/${notification.id}/read`, {});
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ["notifications-inbox", true],
			});
		},
	});

	const acceptFriendRequestMutation = useMutation({
		mutationFn: async (requestId: string) => {
			return Post(`/friends/requests/${requestId}/accept`, {});
		},
	});

	const declineFriendRequestMutation = useMutation({
		mutationFn: async (requestId: string) => {
			return Post(`/friends/requests/${requestId}/deny`, {});
		},
	});

	return {
		markAsReadMutation,
		acceptFriendRequestMutation,
		declineFriendRequestMutation,
	};
};
