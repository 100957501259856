import { useNotifications } from "src/providers/notificationProvider";
import useTTRouter from "src/hooks/useTTRouter";
import { useAuth } from "src/providers/authProvider";
import { useAccount } from "src/providers/accountProvider";
import { PartyProvider, useParty } from "src/providers/partyProvider";
import { useParams } from "react-router-dom";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useEffect, useState } from "react";
import EndGameRewardsSheet from "src/components/EndGameSheet";
import { FullScreenLoadingOverlay } from "src/components/LoadingOverlay";

enum GameMessageType {
	READY = "GAME_FULLY_READY",
	QUIT = "GAME_QUIT",
	SCORE = "GAME_SCORE",
	DID = "SET_DID",
}

interface GameMessage {
	type: string;
	data?: { [key: string]: string };
}

export function GamePage() {
	const { partyId, gameId } = useParams();

	if (!partyId || !gameId) return <>Invalid route...</>;

	return (
		<PartyProvider partyId={partyId}>
			<GameContent />
		</PartyProvider>
	);
}

function GameContent() {
	const { token } = useAuth();
	const { refreshBalances } = useAccount();
	const { party, isPartyQueryLoading, submitHighscore } = useParty();
	const { logError } = useNotifications();
	const { navigateBack } = useTTRouter();

	const [openRewardsSheet, setOpenRewardsSheet] = useState(false);
	const [rewardAmount, setRewardAmount] = useState<number | string>(0);

	function handleClose() {
		console.log("Closing sheet...");
		setOpenRewardsSheet(false);
		navigateBack();
	}

	async function handleComplete(score: number) {
		submitHighscore({ score });
		refreshBalances();

		setRewardAmount(score);
		setOpenRewardsSheet(true);
	}

	if (!token) return <p>Invalid token...</p>;
	if (!party) return <p>Invalid party...</p>;

	const gameUrl = party?.game.gameUrl;
	const url = gameUrl?.slice(0, gameUrl.lastIndexOf("/"));

	return (
		<div className="h-screen w-screen">
			<div className="h-screen w-full py-10">
				{!openRewardsSheet && (
					<GameInstance
						baseURl={url}
						isPartyLoading={isPartyQueryLoading}
						token={token}
						onComplete={handleComplete}
						onError={(error: string) => logError("Game Error", error)}
						onQuit={navigateBack}
					/>
				)}
			</div>
			<EndGameRewardsSheet
				open={openRewardsSheet}
				background={party.game.icon}
				onClose={handleClose}
				totalTrophies={rewardAmount}
			/>
		</div>
	);
}

function GameInstance({
	baseURl,
	token,
	onError,
	onQuit,
	isPartyLoading,
	onComplete,
}: Readonly<{
	baseURl: string;
	token: string;
	isPartyLoading: boolean;
	onError: (error: any) => void;
	onQuit: () => void;
	onComplete: (score: number) => void;
}>) {
	const { unityProvider, sendMessage, isLoaded, unload, loadingProgression } =
		useUnityContext({
			loaderUrl: `${baseURl}/Build/game.loader.js`,
			dataUrl: `${baseURl}/Build/game.data`,
			frameworkUrl: `${baseURl}/Build/game.framework.js`,
			codeUrl: `${baseURl}/Build/game.wasm`,
		});

	async function handleReceiveMessage(event: MessageEvent) {
		console.log("Game Instance Sent: ", event.data);
		//@ts-ignore
		const { type, score }: GameMessage = event.data;

		if (!token) {
			onError("Game initialization error");
			return;
		}

		switch (type) {
			case GameMessageType.READY:
				console.log("App: Sending did");
				sendMessage("TicTapsManager", "ReceiveDID", token);
				break;
			case GameMessageType.QUIT:
				console.log("App: Quitting game");
				onQuit();
				break;
			case GameMessageType.SCORE:
				console.log("App: Saving Score");
				onComplete(score);
				break;
		}
	}

	useEffect(() => {
		if (isLoaded) {
			console.log("Adding event");
			window.addEventListener("message", handleReceiveMessage);
			return () => {
				window.removeEventListener("message", handleReceiveMessage);
				unload();
			};
		}
	}, [isLoaded]);

	const loadingPercentage = Math.round(loadingProgression * 100);

	return (
		<div className="flex items-center justify-center w-full h-full">
			<FullScreenLoadingOverlay
				display={isPartyLoading || !isLoaded}
				description={
					isPartyLoading
						? "Loading party..."
						: `Loading game... ${loadingPercentage}%`
				}
			/>
			<Unity
				className="w-full h-full object-cover"
				unityProvider={unityProvider}
			/>
		</div>
	);
}
