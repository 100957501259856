import { useState } from "react";
import styles from "./index.module.css";
import { SearchInput } from "../SearchInput";
import { ShareButton } from "../ShareButton";
import { ListSheet } from "../ListSheet";
import type { Participant } from "src/types";
import { useFriends } from "src/hooks/useFriends";
import { LoadingSpinner } from "../LoadingOverlay";
import { FriendInviteRow } from "./FriendInviteRow";

export const InviteFriendsSheet = ({
	isOpen,
	onClose,
	participants,
	onShare,
}: {
	isOpen: boolean;
	onClose: () => void;
	participants: Participant[];
	onShare: () => void;
}) => {
	const { friends, isLoading } = useFriends();
	const [searchQuery, setSearchQuery] = useState("");

	const filteredFriends =
		friends?.filter((friend) =>
			friend.gamer_tag.toLowerCase().includes(searchQuery.toLowerCase()),
		) ?? [];

	return (
		<ListSheet isOpen={isOpen} onClose={onClose} title="Invite friends">
			<div className={styles.searchContainer}>
				<SearchInput
					value={searchQuery}
					onChange={setSearchQuery}
					placeholder="Search by name"
				/>
				<ShareButton onClick={onShare} />
			</div>

			{isLoading ? (
				<div className={styles.loading}>
					<LoadingSpinner />
				</div>
			) : (
				<ul className={styles.friendList}>
					{filteredFriends.map((friend) => (
						<FriendInviteRow
							key={friend.id}
							friend={friend}
							onInvite={() => Promise.resolve()}
							onCancel={() => Promise.resolve()}
							isParticipant={participants?.some(
								(p) => p.profile.id === friend.friend_id,
							)}
						/>
					))}
				</ul>
			)}
		</ListSheet>
	);
};
