import { useEffect, useState } from "react";
import {
	Chat,
	Channel,
	Window,
	ChannelHeader,
	MessageList,
	MessageInput,
	Thread,
	useCreateChatClient,
} from "stream-chat-react";
import { useParams } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import useTTRouter from "src/hooks/useTTRouter";
import "stream-chat-react/dist/css/v2/index.css";
import "./partyChat.css";
import { useAccount } from "src/providers/accountProvider";
import { LoadingSpinner } from "src/components/LoadingOverlay";

const apiKey = "rp37rkfubtfp";

export function ChatPage() {
	const { account } = useAccount();
	const client = useCreateChatClient({
		apiKey,
		tokenOrProvider: account?.chatToken,
		userData: {
			id: account?.id ?? "",
			name: account?.nickName,
			image: account?.profileImage,
		},
	});

	if (!client) return <LoadingSpinner />;

	return (
		<Chat client={client}>
			<_ChatPage client={client} />
		</Chat>
	);
}

function _ChatPage({ client }: { client: any }) {
	const { navigateBack } = useTTRouter();
	const { chatId } = useParams();

	const [channel, setChannel] = useState();

	useEffect(() => {
		if (client && chatId) {
			createChannel();
		}
	}, []);

	async function createChannel() {
		const channel = client.channel("messaging", chatId);
		if (channel) {
			channel.watch().then(() => {
				// @ts-ignore
				setChannel(channel);
			});
		}
	}

	if (!client || !channel) {
		return (
			<p className="text-white">
				Contact support, encountered a problem with the chat!
			</p>
		);
	}

	return (
		<div className="h-screen pt-12 pb-8">
			<Channel channel={channel}>
				<Window>
					<div className="flex flex-row items-center ">
						<button
							type="button"
							aria-label="Back"
							className="pl-3 pr-2"
							onClick={() => navigateBack()}
						>
							<IoChevronBack size={24} />
						</button>
						<ChannelHeader />
					</div>
					<MessageList />
					<MessageInput />
				</Window>
				<Thread />
			</Channel>
		</div>
	);
}
