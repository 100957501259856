import { useQuery } from "@tanstack/react-query";
import { Get } from "src/api/fetch.wrapper";
import type { UserAccount } from "src/api/types";

export const useUserSearch = (query: string, limit = 100, offset = 0) => {
	const { data, isLoading, error } = useQuery<UserAccount[]>({
		queryKey: ["user-search", query, limit, offset],
		queryFn: () =>
			Get(`users/search?query=${query}&limit=${limit}&offset=${offset}`),
		enabled: !!query,
	});

	return {
		users: data,
		isLoading,
		error,
	};
};
