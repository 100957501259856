export const registerServiceWorker = () => {
	if ("serviceWorker" in navigator && window.location.protocol === "https:") {
		window.addEventListener("load", async () => {
			try {
				const registration =
					await navigator.serviceWorker.register("/service-worker.js");
				console.log("SW registered: ", registration);

				setInterval(
					async () => {
						try {
							await registration.update();
						} catch (err) {
							console.error("Error checking for SW updates:", err);
						}
					},
					60 * 60 * 1000, // Check every hour
				);

				registration.addEventListener("updatefound", () => {
					const newWorker = registration.installing;
					if (newWorker) {
						newWorker.addEventListener("statechange", () => {
							if (
								newWorker.state === "installed" &&
								navigator.serviceWorker.controller
							) {
								const updateConfirmed = window.confirm(
									"A new version is available. Do you want to update now?",
								);

								if (updateConfirmed) {
									newWorker.postMessage({ type: "SKIP_WAITING" });
									window.location.reload();
								}
							}
						});
					}
				});
			} catch (error) {
				console.error("Service worker registration failed:", error);
			}

			let refreshing = false;
			navigator.serviceWorker.addEventListener("controllerchange", () => {
				if (!refreshing) {
					refreshing = true;
					window.location.reload();
				}
			});
		});
	}
};
