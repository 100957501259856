import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";
import { ttRoutes } from "src/hooks/useTTRouter";

export function AddPartyCard() {
	return (
		<NavLink to={ttRoutes.createParty()}>
			<div className="relative w-40">
				<div className="flex flex-col h-44 items-center justify-center backdrop-blur relative bg-gradient-to-br to-white/10 via-[#34414d]/10 from-[#34414d]/10 border-hairline border-[#34414d]/30 rounded-xl p-3 space-y-2">
					<PlusCircleIcon className="fill-white size-10 opacity-30" />
					<p className="text-sm text-white opacity-60">Start a party now!</p>
				</div>
			</div>
		</NavLink>
	);
}
