import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import type { Game, Party } from "../types";
import Sheen from "./Sheen";

function FormatTimeLeft(endDate: Date): {
	hasEnded: boolean;
	timeLeft: string;
} {
	const currentTime = new Date();
	const timeDifferenceInMs = endDate.getTime() - currentTime.getTime();

	const hoursLeft = Math.floor(timeDifferenceInMs / (1000 * 60 * 60));
	const minutesLeft = Math.floor(
		(timeDifferenceInMs % (1000 * 60 * 60)) / (1000 * 60),
	);

	return {
		hasEnded: timeDifferenceInMs <= 0,
		timeLeft: `${hoursLeft}h ${minutesLeft}m`,
	};
}
export default function PartyCard({
	game,
	onClick,
	party,
}: {
	game: Game;
	onClick: () => void;
	party: Party;
}) {
	const { hasEnded, timeLeft } = FormatTimeLeft(party.endDate);

	return (
		<div className="relative h-44 max-w-40 min-w-40 bg-neutral-700 rounded-xl overflow-hidden">
			<Sheen speed={4.0}>
				<img src={game.icon} alt="" />
			</Sheen>

			<div className="absolute top-0 w-full">
				<div className="w-full flex justify-between p-1 gap-x-2">
					<div className="relative w-full h-full">
						<div className="absolute w-full h-full backdrop-blur rounded-lg" />
						<div className=" font-semibold text-yellow-500 text-xs  relative bg-gray-900/60 rounded-lg px-2 p-1">
							<p>{party.name}</p>
						</div>
					</div>
					<div className="relative w-fit h-full">
						<div className="absolute w-fit h-full backdrop-blur-xl rounded-xl" />
						<div className="flex font-semibold items-center space-x-1 text-white text-xs  relative bg-gray-900/60 rounded-lg px-2 p-1">
							<FontAwesomeIcon color="#ffffff" icon={faPeopleGroup} />
							<p className="text-white font-semibold text-xs">
								{party.participants}
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="absolute bottom-0 w-full px-1 space-y-1 pb-1">
				<div className="relative w-full h-full">
					<div className="absolute w-full h-full backdrop-blur rounded-lg" />
					<div className="font-semibold text-xs relative bg-gray-900/60 rounded-lg px-2 p-1 flex justify-between items-center">
						{!hasEnded && (
							<>
								<p className="text-gray-300 font-semibold text-xs flex items-center">
									<FontAwesomeIcon icon={faClock} className="mr-1" /> {timeLeft}
								</p>
								<p className="text-gray-300 font-semibold text-xs flex items-center">
									{party.currencyType === "FIAT" ? (
										<>
											<img
												src="/icons/coin.webp"
												alt="coin icon"
												className="w-3 mr-1"
											/>{" "}
											{party.buyInAmount}
										</>
									) : (
										<>
											<img
												src="/icons/ttoken.webp"
												alt="token icon"
												className="w-3 mr-1"
											/>{" "}
											{party.buyInAmount}
										</>
									)}
								</p>
							</>
						)}
						{hasEnded && (
							<p className="text-gray-300 font-semibold text-xs">
								{" "}
								<FontAwesomeIcon icon={faClock} /> Ended
							</p>
						)}
					</div>
				</div>

				<button
					type="button"
					onClick={() => onClick()}
					className="bg-gradient-to-br from-[#00FFAA] via-[#4579F5] to-[#9C42F5] w-full rounded-lg px-2 py-1"
				>
					<p className="text-white font-semibold ">
						{hasEnded ? "View" : "Play"}
					</p>
				</button>
			</div>
		</div>
	);
}
