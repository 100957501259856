import { useNavigate } from "react-router-dom";

export const ttRoutes = {
	home: () => "/app/home",
	createParty: () => "/app/party/create",
	createPartyWithGame: (gameId?: string) =>
		`/app/party/create/game/${gameId ?? ":gameId"}`,
	partyChat: (chatId?: string) => `/app/party/chat/${chatId ?? ":chatId"}`,
	game: (gameId?: string, partyId?: string) =>
		`/app/party/${partyId ?? ":partyId"}/game/${gameId ?? ":gameId"}`,
	login: () => "/app/auth/login",
	followers: () => "/app/followers",
	friends: () => "/app/friends",
	userHistory: (userId?: string) => `/app/history/${userId ?? ":userId"}`,
	party: (partyId?: string) => `/app/party/${partyId ?? ":partyId"}`,
	myProfile: () => "/app/profile",
	userProfile: (userId?: string) => `/app/profile/user/${userId ?? ":userId"}`,
	friendProfile: (userId?: string) =>
		`/app/profile/friend/${userId ?? ":userId"}`,
	leaderboard: (partyId?: string) =>
		`/app/leaderboard/${partyId ?? ":partyId"}`,
	paymentReturn: () => "/app/payment-return",
	loadMoney: () => "/app/payment/loadMoney",
	logout: () => "/app/logout",
	test: () => "/app/test",
	settings: () => "/app/settings",
};

export default function useTTRouter() {
	const navigate = useNavigate();

	function navigateToTest() {
		navigate(ttRoutes.test());
	}

	function navigateToSettings() {
		navigate(ttRoutes.settings());
	}

	function navigateToGame(gameId: string, partyId: string) {
		navigate(ttRoutes.game(gameId, partyId));
	}

	function navigateToLogin() {
		navigate(ttRoutes.login());
	}

	function navigateToParty(partyId: string) {
		navigate(ttRoutes.party(partyId));
	}

	function navigateToPartyWithJoin(partyId: string, autoJoin: boolean) {
		const url = `${ttRoutes.party(partyId)}?autoJoin=${autoJoin}`;
		navigate(url);
	}

	function navigateToPartyChat(chatId: string) {
		navigate(ttRoutes.partyChat(chatId));
	}

	function navigateToMyProfile() {
		navigate(ttRoutes.myProfile());
	}

	function navigateToUserProfile(userId: string) {
		navigate(ttRoutes.userProfile(userId));
	}

	function navigateToLeaderboard(partyId: string) {
		navigate(ttRoutes.leaderboard(partyId));
	}

	function navigateToPartyCreation() {
		navigate(ttRoutes.createParty());
	}

	function navigateToPartyCreateWithGame(gameId: string) {
		navigate(ttRoutes.createPartyWithGame(gameId));
	}

	function navigateToUserHistory(userId: string) {
		navigate(ttRoutes.userHistory(userId));
	}

	function navigateToFollowers() {
		navigate(ttRoutes.followers());
	}

	function navigateBack() {
		navigate(-1);
	}

	function navigateToOnboardMoney() {
		navigate(ttRoutes.loadMoney());
	}

	function navigateToLogout() {
		navigate(ttRoutes.logout());
	}

	return {
		navigateToPartyChat,
		navigateToGame,
		navigateToLogin,
		navigateToParty,
		navigateToPartyWithJoin,
		navigateToPartyCreateWithGame,
		navigateToPartyCreation,
		navigateToMyProfile,
		navigateToUserProfile,
		navigateToUserHistory,
		navigateToLeaderboard,
		navigateToFollowers,
		navigateBack,
		navigateToOnboardMoney,
		navigateToLogout,
		navigateToTest,
		navigateToSettings,
	};
}
