import { useNavigate, useParams } from "react-router-dom";
import { UserProfileComponent } from "src/components/UserProfileComponent";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { FullScreenLoadingOverlay } from "src/components/LoadingOverlay";
import { useFriend } from "src/hooks/useFriend";
import { APIError } from "src/components/APIError";
import PartyHistoryCard from "src/components/PartyHistoryCard";

export const FriendDetail = () => {
	const { userId } = useParams<{ userId: string }>();
	const { friend, isLoading, error, history } = useFriend(userId);
	const navigate = useNavigate();

	const handleBackClick = () => {
		navigate(-1);
	};

	if (!userId) {
		return (
			<p className="text-cyan-500 font-light text-2xl">
				Friend not found, contact support.
			</p>
		);
	}

	if (error) {
		return <APIError title="Error loading friend" error={error} />;
	}

	if (isLoading || !friend) {
		return (
			<FullScreenLoadingOverlay
				display={isLoading}
				description="Loading profile..."
			/>
		);
	}

	return (
		<div className="h-full overflow-y-auto">
			<div className="flex flex-col justify-center space-y-4 relative z-[30]">
				<button
					type="button"
					onClick={handleBackClick}
					aria-label="Back"
					className="absolute z-[60] top-4 px-2 left-4 mt-1 bg-transparent text-white rounded-full hover:bg-gray-600"
				>
					<ChevronLeftIcon className="h-8 w-8" />
				</button>
				<UserProfileComponent
					profile={{
						profileImage: friend?.profile_image,
						gamertag: friend?.gamer_tag,
					}}
					winRatio={0}
				/>
			</div>

			<div className="flex flex-col gap-y-2 relative z-[10] mb-60 pt-2 mt-4">
				{history?.entries.length === 0 ? (
					<div className="flex justify-center items-center h-full pt-16">
						<p className="text-lg text-white font-light">
							No games played yet 😔
						</p>
					</div>
				) : (
					<div className="flex flex-col space-y-1 px-2">
						<h3 className="text-md text-white font-semibold ">Game History</h3>
						<div className="flex flex-row w-full justify-between backdrop-blur rounded-lg">
							<div className="flex overflow-x-scroll space-x-2 pt-2 px-2 pb-2">
								{history?.entries.map((party) => {
									const game = {
										id: party.game.id,
										icon: party.game.icon_url,
										gamePreview: party.game.preview_url,
										gameplayPreview: party.game.banner_url,
										name: party.game.name,
										description: party.game.description,
										gameUrl: party.game.game_url,
									};

									const partyInfo = {
										id: party.party_id,
										date: party.date,
										score: party.score?.toString() ?? "0",
										position: party.position?.toString() ?? "0",
										outcome: party.outcome,
										game,
									};
									return (
										<PartyHistoryCard
											key={party.party_id}
											game={game}
											party={partyInfo}
										/>
									);
								})}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
