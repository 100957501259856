import useTTRouter from "src/hooks/useTTRouter";
import GradientContainer from "./GradientContainer";
import { IoChatbubble } from "react-icons/io5";

export const ChatButton = ({
	isDisabled,
	chatChannelId,
}: {
	isDisabled: boolean;
	chatChannelId: string;
}) => {
	const { navigateToPartyChat } = useTTRouter();

	if (isDisabled) {
		return (
			<div className="fixed bottom-24 right-6 opacity-50">
				<GradientContainer styles="rounded-full text-white">
					<div className="p-2">
						<IoChatbubble size={18} />
					</div>
				</GradientContainer>
			</div>
		);
	}

	return (
		<div className="fixed bottom-24 right-6">
			<GradientContainer styles="rounded-full text-white">
				<button
					type="button"
					aria-label="Chat"
					className="p-2"
					onClick={() => {
						navigateToPartyChat(chatChannelId ?? "");
					}}
				>
					<IoChatbubble size={18} />
				</button>
			</GradientContainer>
		</div>
	);
};
