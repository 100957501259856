"use client";
import { useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link as ScrollLink } from "react-scroll";

export default function NavLinks() {
	const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
	const timeoutRef = useRef<number | null>(null);

	return (
		<>
			{[["How it works", "features"]].map(([label, targetId], index) => (
				<ScrollLink
					key={label}
					to={targetId}
					smooth={true}
					offset={-70}
					duration={500}
					className="relative -mx-3 -my-2 rounded-lg px-3 py-2 text-sm text-white transition-colors delay-150 hover:text-cyan-500 hover:delay-0 cursor-pointer"
					onMouseEnter={() => {
						if (timeoutRef.current) {
							window.clearTimeout(timeoutRef.current);
						}
						setHoveredIndex(index);
					}}
					onMouseLeave={() => {
						timeoutRef.current = window.setTimeout(() => {
							setHoveredIndex(null);
						}, 200);
					}}
				>
					<AnimatePresence>
						{hoveredIndex === index && (
							<motion.span
								className="absolute inset-0 rounded-lg"
								layoutId="hoverBackground"
								initial={{ opacity: 0 }}
								animate={{ opacity: 1, transition: { duration: 0.15 } }}
								exit={{
									opacity: 0,
									transition: { duration: 0.15 },
								}}
							/>
						)}
					</AnimatePresence>
					<span className="relative z-10">{label}</span>
				</ScrollLink>
			))}
		</>
	);
}
