import { useQuery } from "@tanstack/react-query";
import { Get } from "src/api/fetch.wrapper";

const THIRTY_SECONDS = 30000;

export const useNotificationsCount = () => {
	const { data, isLoading, error } = useQuery<number>({
		queryKey: ["notifications-count"],
		refetchInterval: THIRTY_SECONDS,
		queryFn: () => Get("/notifications/inbox/count"),
	});
	return {
		notificationsCount: data,
		isLoading,
		error,
	};
};
