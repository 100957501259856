import type { Currency, Game } from "src/types";
import { useBubblesBackground } from "src/providers/layoutProvider";
import {
	Listbox,
	ListboxButton,
	ListboxOption,
	ListboxOptions,
	Transition,
} from "@headlessui/react";
import useGames from "src/hooks/useGames";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronDown,
	faCog,
	faGamepad,
} from "@fortawesome/free-solid-svg-icons";
import { Sheet } from "react-modal-sheet";
import useParties from "src/hooks/useParties";
import useTTRouter from "src/hooks/useTTRouter";
import { currencies, useAccount } from "src/providers/accountProvider";
import { useNotifications } from "src/providers/notificationProvider";
import { LoadingSpinner } from "src/components/LoadingOverlay";
import { VerticalGamesList } from "src/components/VerticalGamesList";
import { useStorageState } from "src/hooks/useStorageState";
import { GradientBorder } from "src/components/GradientContainer";
import { useParams } from "react-router";
import { PrimaryButton } from "src/components/PrimaryButton";
import { cn } from "src/utils/cn";

export interface CreatePartySettings {
	name: string;
	isPrivate: boolean;
	buyIn: number;
	currency: Currency;
	game: Game;
	duration: string;
	inviteCode: string | null;
}

export interface CreatePartyView {
	currency: Currency | null;
	name: string | null;
	inviteCode: string | null;
	buyIn: string;
	showGameSelection: boolean;
	game: Game | null;
	isPrivate: boolean;
}

export function CreatePartyPage() {
	useBubblesBackground();
	const { refreshBalances } = useAccount();
	const { data: games } = useGames();
	const { balance } = useAccount();
	const { account } = useAccount();
	const { gameId } = useParams();
	const { navigateToParty } = useTTRouter();
	const { logError, logWarning } = useNotifications();
	const { createParty } = useParties();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [showForm, setShowForm] = useState<boolean>(false);
	const [settings, setSettings] = useStorageState<CreatePartySettings | null>(
		`${account?.id}:partySettings`,
		null,
		(value) => JSON.stringify(value),
		(value) => JSON.parse(value),
	);
	const [partyDetails, setPartyDetails] = useState<CreatePartyView>({
		currency: null,
		name: null,
		inviteCode: null,
		buyIn: "0",
		showGameSelection: false,
		game: null,
		isPrivate: true,
	});

	useEffect(() => {
		const previousSettings = {
			currency: settings ? settings.currency : null,
			name: settings ? settings.name : null,
			inviteCode: settings ? settings.inviteCode : null,
			buyIn: settings ? settings.buyIn.toString() : "0",
			showGameSelection: false,
			game: settings ? settings.game : null,
			isPrivate: settings ? settings.isPrivate : true,
		};
		if (gameId !== undefined) {
			const game = games.find((x) => x.id === gameId);
			previousSettings.game = game === undefined ? null : game;
		}

		setPartyDetails(() => previousSettings);
		setIsLoading(false);
	}, [settings]);

	const acceptableCurrencies = currencies.filter((c) =>
		["USD", "TTT"].includes(c.code),
	);

	async function onSubmit() {
		const { name, buyIn, game, currency, isPrivate } = partyDetails;

		if (!name || !buyIn || !game || !currency) {
			logError("Invalid form", "Please fill out all fields");
			return;
		}

		const selectedCurrencyBalance =
			currency.code === "TTT" ? balance.ticTapsTokens : balance.dollars;

		if (Number(buyIn) > selectedCurrencyBalance) {
			logWarning(
				"Insufficient Balance",
				`You do not have enough ${currency.code} to create this party.`,
			);
			return;
		}

		setIsLoading(true);
		const partySettings = {
			name,
			isPrivate,
			buyIn: Number(buyIn),
			currency: currency,
			game,
			duration: "5",
			inviteCode: null,
		};

		try {
			const partyId = await createParty(partySettings);
			navigateToParty(partyId);
			await refreshBalances();
		} catch (err) {
			logError("Create Party", "Failed to create party");
		}
		setIsLoading(false);
		setSettings(partySettings);
	}

	function handleClick() {
		const { buyIn, currency } = partyDetails;

		if (isLoading) return;

		if (!currency) {
			logWarning(
				"Missing Currency",
				"Please select a currency before proceeding.",
			);
			return;
		}

		const selectedCurrencyBalance =
			currency.code === "TTT" ? balance.ticTapsTokens : balance.dollars;

		if (Number(buyIn) > selectedCurrencyBalance) {
			logWarning(
				"Insufficient Balance",
				`You do not have enough ${currency.code} to create this party.`,
			);
			return;
		}

		onSubmit();
	}

	const hasSettings = settings === undefined || settings === null;
	if (isLoading === true)
		return (
			<Transition show={isLoading} afterLeave={() => setShowForm(true)}>
				<div className="h-44 w-full flex justify-center items-center transition duration-150 ease-in data-[closed]:opacity-0">
					<LoadingSpinner />
				</div>
			</Transition>
		);
	return showForm || hasSettings ? (
		<>
			<div className="relative flex flex-col gap-y-6 px-4 min-h-screen pb-60">
				<div className="w-full md:w-2/3 lg:w-1/2 mx-auto">
					<div className="relative w-full h-full">
						<div className="absolute w-full h-full backdrop-blur-3xl rounded-xl" />
						<div className="flex flex-col font-semibold space-x-1 text-white border-[#34414d]  relative  bg-gradient-to-tl to-white/10 via-[#34414d]/10 from-[#1b2229]/70 rounded-lg p-4 space-y-4">
							<div className="flex flex-col space-y-2">
								<label
									className="text-white text-sm font-medium"
									htmlFor="name"
								>
									Name of the party 😊
								</label>
								<div className="flex">
									<input
										id="name"
										className=" w-full placeholder:text-gray-300 font-light  focus:outline-none flex-grow border-hairline border-[#34414d] rounded-2xl p-4 bg-tic-taps-grey/20 text-white"
										placeholder="Name"
										autoCapitalize="none"
										autoComplete="off"
										autoCorrect="off"
										onChange={(e) =>
											setPartyDetails((prev) => ({
												...prev,
												name: e.target.value,
											}))
										}
										value={partyDetails.name ?? ""}
									/>
								</div>
							</div>

							<div className="flex flex-col space-y-2">
								<p className="text-white text-sm font-medium">Game</p>

								<button
									type="button"
									onClick={() =>
										setPartyDetails((prev) => ({
											...prev,
											showGameSelection: true,
										}))
									}
									className="flex justify-start items-center space-x-2 w-full font-light flex-grow border-hairline border-[#34414d] rounded-2xl p-4 bg-tic-taps-grey/20 text-white"
								>
									{partyDetails.game ? (
										<>
											<img
												alt=""
												src={partyDetails.game?.icon}
												className="w-10 h-10 rounded-lg"
											/>
											<p className="text-lg font-semibold">
												{partyDetails.game?.name}
											</p>
										</>
									) : (
										<>
											<FontAwesomeIcon icon={faGamepad} />
											<p className="text-gray-300">Select a game</p>
										</>
									)}
								</button>
							</div>

							<div className="flex flex-col space-y-2">
								<p className="text-white text-sm font-medium">Currency Type</p>

								<Listbox
									value={partyDetails.currency}
									onChange={(c) =>
										setPartyDetails((prev) => ({ ...prev, currency: c }))
									}
								>
									<ListboxButton
										className={cn(
											"w-full font-light flex-grow border-hairline border-[#34414d] rounded-2xl p-4 bg-tic-taps-grey/20 text-white",
										)}
									>
										<div className="w-full flex items-center justify-between">
											{partyDetails.currency ? (
												<p>{partyDetails.currency.code}</p>
											) : (
												<p className="text-gray-300">Select a currency</p>
											)}
											<FontAwesomeIcon
												icon={faChevronDown}
												className="group pointer-events-none size-4 fill-white/60"
											/>
										</div>
									</ListboxButton>
									<ListboxOptions
										anchor="bottom"
										transition
										className={cn(
											"w-[var(--button-width)] backdrop-blur-sm rounded-xl border border-white/5 bg-white/5 p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none",
											"transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0",
										)}
									>
										{acceptableCurrencies.map((currency, index) => (
											<ListboxOption
												key={currency.code}
												value={currency}
												className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
											>
												<FontAwesomeIcon
													icon={faChevronDown}
													className="invisible size-4 fill-white group-data-[selected]:visible"
												/>
												<div className="text-sm/6 text-white">
													{currency.code}
												</div>
											</ListboxOption>
										))}
									</ListboxOptions>
								</Listbox>
							</div>

							<div className="flex flex-col space-y-2">
								<p className="text-white text-sm font-medium">Buy in</p>

								<input
									className=" w-full placeholder:text-gray-300 font-light  focus:outline-none flex-grow border-hairline border-[#34414d] rounded-2xl p-4 bg-tic-taps-grey/20 text-white"
									placeholder="Buy In"
									autoCapitalize="none"
									autoComplete="off"
									autoCorrect="off"
									type="number"
									onChange={(e) =>
										setPartyDetails((prev) => ({
											...prev,
											buyIn: e.target.value,
										}))
									}
									value={partyDetails.buyIn}
								/>
							</div>

							<PrimaryButton onClick={handleClick}>
								{isLoading ? <LoadingSpinner /> : "Create Party"}
							</PrimaryButton>
						</div>
					</div>
				</div>
			</div>
			<Sheet
				isOpen={partyDetails.showGameSelection}
				onClose={() =>
					setPartyDetails((prev) => ({ ...prev, showGameSelection: false }))
				}
			>
				<Sheet.Container>
					<Sheet.Header className="bg-tic-taps-grey" />
					<Sheet.Content className="bg-tic-taps-grey">
						<VerticalGamesList
							options={games}
							value={partyDetails.game}
							onChange={(game) =>
								setPartyDetails((prev) => ({
									...prev,
									game,
									showGameSelection: false,
								}))
							}
						/>
					</Sheet.Content>
				</Sheet.Container>
				<Sheet.Backdrop className="bg-tic-taps-grey" />
			</Sheet>
		</>
	) : (
		<div className="relative flex flex-col gap-y-6 px-4 min-h-screen pb-60">
			<div className="w-full md:w-2/3 lg:w-1/2 mx-auto">
				<div className="relative w-full h-full">
					<div className="absolute w-full h-full backdrop-blur-3xl rounded-xl" />
					<div className="flex flex-col font-semibold space-x-1 text-white border-[#34414d]  relative  bg-gradient-to-tl to-white/10 via-[#34414d]/10 from-[#1b2229]/70 rounded-lg p-4 space-y-4">
						<div className="absolute z-[80] right-8 text-white rounded-xl">
							<GradientBorder padding="p-0.5" rounded="rounded-lg">
								<button
									type="button"
									aria-label="Settings"
									className="flex items-center justify-center bg-transparent text-cyan-500 p-2 rounded-xl text-sm font-semibold shadow-md"
									onClick={() => setShowForm(true)}
								>
									<FontAwesomeIcon icon={faCog} />
								</button>
							</GradientBorder>
						</div>

						<div className="flex flex-col space-y-2">
							<p className="text-white text-sm font-medium">
								Name of the party 😊
							</p>
							<div className="flex">
								<input
									className=" w-full placeholder:text-gray-300 font-light  focus:outline-none flex-grow border-hairline border-[#34414d] rounded-2xl p-4 bg-tic-taps-grey/20 text-white"
									placeholder="Name"
									autoCapitalize="none"
									autoComplete="off"
									autoCorrect="off"
									onChange={(e) =>
										setPartyDetails((prev) => ({
											...prev,
											name: e.target.value,
										}))
									}
									value={partyDetails.name ?? ""}
								/>
							</div>
							<button
								type="button"
								onClick={handleClick}
								className="my-2 disabled:bg-none transition-all disabled:bg-gray-500 py-4 ease-in-out duration-150 bg-gradient-to-br from-[#00FFAA] via-[#4579F5] to-[#9C42F5] w-full rounded-2xl text-white"
							>
								{isLoading ? <LoadingSpinner /> : "Create Party"}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
