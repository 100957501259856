export function Logomark() {
	return (
		<svg
			role="presentation"
			width="100%"
			height="100%"
			viewBox="0 0 152 152"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="76" cy="76" r="76" fill="url(#paint0_linear_800_2324)" />
			<path
				d="M42.8333 57.8937H64.3545L47.0531 85.9167H68.3637L83.3435 59.5667H102.755L112.25 43.4639L53.1719 42L42.8333 57.8937Z"
				fill="white"
			/>
			<path
				d="M109.417 94.1051H87.8954L105.197 66.082H83.8862L68.9064 92.432H49.4947L40 108.535L99.0778 109.999L109.417 94.1051Z"
				fill="white"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_800_2324"
					x1="-75.8877"
					y1="75.8877"
					x2="75.8877"
					y2="227.663"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#00FFAA" />
					<stop offset="0.530854" stopColor="#4579F5" />
					<stop offset="0.999815" stopColor="#9C42F5" />
				</linearGradient>
			</defs>
		</svg>
	);
}

export function Logo(props: React.ComponentPropsWithoutRef<"svg">) {
	return (
		<div className="h-12 w-12 flex-none shadow-glow rounded-full">
			<Logomark />
		</div>
	);
}
