import { useState } from "react";
import { ListSheet } from "../ListSheet";
import { SearchInput } from "../SearchInput";
import { useNotificationsInbox } from "src/hooks/useNotificationsInbox";
import styles from "./index.module.css";
import type { Notification } from "src/api/types";
import { LoadingSpinner } from "../LoadingOverlay";
import { RowActionButton } from "../RowActionButton";
import { ActionButton } from "../ActionButton";
export const NotificationsSheet = ({
	isOpen,
	onClose,
	onViewNotification,
}: {
	isOpen: boolean;
	onClose: () => void;
	onViewNotification: (notification: Notification) => void;
}) => {
	const [search, setSearch] = useState("");
	const [onlyUnread, setOnlyUnread] = useState(true);

	const { notifications, isLoading, error, markAllAsReadMutation } =
		useNotificationsInbox(isOpen, onlyUnread);

	const isEmpty = !notifications?.length;

	const filteredNotifications = notifications?.filter((notification) =>
		notification.title.toLowerCase().includes(search.toLowerCase()),
	);

	return (
		<ListSheet isOpen={isOpen} onClose={onClose} title="Notifications">
			<SearchInput
				value={search}
				onChange={setSearch}
				placeholder="Search by name"
				disabled={isLoading || !!error || isEmpty}
			/>
			<div className={styles.actions}>
				<ActionButton
					disabled={isLoading || !!error}
					onClick={() => setOnlyUnread(!onlyUnread)}
					className={styles.actionButton}
				>
					{onlyUnread ? "Show all" : "Show unread"}
				</ActionButton>
				<ActionButton
					type="button"
					onClick={() => markAllAsReadMutation.mutate()}
					disabled={
						isLoading || !!error || isEmpty || markAllAsReadMutation.isPending
					}
					className={styles.actionButton}
				>
					{markAllAsReadMutation.isPending
						? "Marking as read..."
						: "Mark all as read"}
				</ActionButton>
			</div>
			{isLoading ? (
				<div className={styles.status}>
					<LoadingSpinner />
				</div>
			) : error ? (
				<div className={styles.status}>
					<p>Error retrieving notifications</p>
				</div>
			) : isEmpty ? (
				<div className={styles.status}>All notifications have been read 🎉</div>
			) : (
				<ul className={styles.list}>
					{filteredNotifications?.map((notification) => (
						<li key={notification.id} className={styles.notification}>
							<div className={styles.notificationContent}>
								<h3 className={styles.title}>{notification.title}</h3>
								<p className={styles.body}>{notification.body}</p>
							</div>
							<RowActionButton onClick={() => onViewNotification(notification)}>
								View
							</RowActionButton>
						</li>
					))}
				</ul>
			)}
		</ListSheet>
	);
};
