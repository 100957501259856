import QRCode from "react-qr-code";
import { ListSheet } from "../ListSheet";
import { Share } from "@capacitor/share";
import type { Party } from "src/types";
import styles from "./index.module.css";
import { PrimaryButton } from "../PrimaryButton";

import bgSrc from "./share-bg.svg";
import copyIconSrc from "./copy.svg";

export const SharePartySheet = ({
	isOpen,
	onClose,
	party,
}: {
	isOpen: boolean;
	onClose: () => void;
	party: Party;
}) => {
	const handleShare = async () => {
		try {
			await Share.share({
				title: "Join the party!",
				text: `Join our party using this invite code: ${party.inviteCode}`,
				url: partyUrl,
				dialogTitle: "Share Party Invite",
			});
		} catch (error) {
			console.error("Error sharing content:", error);
		}
	};

	const handleCopy = () => {
		navigator.clipboard.writeText(partyUrl);
	};

	const currentUrl = window.location.href;
	const baseUrl = currentUrl.slice(0, currentUrl.lastIndexOf("/party"));
	const partyUrl = baseUrl && party?.id ? `${baseUrl}/party/${party.id}` : "";

	return (
		<ListSheet bg={bgSrc} isOpen={isOpen} onClose={onClose} title="Share">
			<div className={styles.qrCodeContainer}>
				<QRCode
					size={256}
					bgColor="transparent"
					fgColor="#fff"
					style={{ height: "200px", width: "200px" }}
					value={partyUrl}
					viewBox="0 0 256 256"
				/>
			</div>
			<p className={styles.inviteCode}>{party.inviteCode}</p>
			<div className={styles.inviteLink}>
				<a
					className={styles.inviteUrl}
					href={partyUrl}
					target="_blank"
					rel="noopener noreferrer"
				>
					{partyUrl}
				</a>
				<button
					className={styles.copyButton}
					type="button"
					onClick={handleCopy}
				>
					<img src={copyIconSrc} alt="Copy" width={16} height={16} />
				</button>
			</div>
			<PrimaryButton className={styles.shareButton} onClick={handleShare}>
				Share
			</PrimaryButton>
		</ListSheet>
	);
};
