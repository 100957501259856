import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { useQueryClient } from "@tanstack/react-query";
import Pusher from "pusher-js";
import type * as PusherTypes from "pusher-js";
import { useCallback, useEffect, useRef } from "react";
import { Post } from "src/api/fetch.wrapper";

const IS_DEVELOPMENT =
	!process.env.NODE_ENV || process.env.NODE_ENV === "development";

const EVENTS = {
	JOIN_EVENT: "send_party_join_event",
	CLOSE_EVENT: "send_party_close_event",
	LEAVE_EVENT: "send_party_leave_event",
};

let beamsClient: PusherPushNotifications.Client | null = null;

if (
	"serviceWorker" in navigator &&
	window.location.protocol === "https:" &&
	!IS_DEVELOPMENT
) {
	navigator.serviceWorker.ready.then((registration) => {
		beamsClient = new PusherPushNotifications.Client({
			instanceId: process.env.REACT_APP_PUSHER_INSTANCE_ID ?? "",
			serviceWorkerRegistration: registration,
		});
	});
}

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY ?? "", {
	cluster: process.env.REACT_APP_PUSHER_CLUSTER ?? "",
});

export const useNotificationsRegistration = () => {
	const queryClient = useQueryClient();
	const joinEventChannel = useRef<PusherTypes.Channel>(
		pusher.subscribe(EVENTS.JOIN_EVENT),
	);
	const closeEventChannel = useRef<PusherTypes.Channel>(
		pusher.subscribe(EVENTS.CLOSE_EVENT),
	);
	const leaveEventChannel = useRef<PusherTypes.Channel>(
		pusher.subscribe(EVENTS.LEAVE_EVENT),
	);

	useEffect(() => {
		joinEventChannel.current.bind(EVENTS.JOIN_EVENT, (data: object) => {
			console.info("Party join event", data);
			queryClient.invalidateQueries({
				predicate: (query) => query.queryKey[0] === "participants",
			});
		});

		closeEventChannel.current.bind(EVENTS.CLOSE_EVENT, (data: object) => {
			console.info("Party close event", data);
			queryClient.invalidateQueries({
				predicate: (query) => query.queryKey[0] === "participants",
			});
		});

		leaveEventChannel.current.bind(EVENTS.LEAVE_EVENT, (data: object) => {
			console.info("Party leave event", data);
			queryClient.invalidateQueries({
				predicate: (query) => query.queryKey[0] === "participants",
			});
		});

		return () => {
			joinEventChannel.current.unbind(EVENTS.JOIN_EVENT);
			closeEventChannel.current.unbind(EVENTS.CLOSE_EVENT);
			leaveEventChannel.current.unbind(EVENTS.LEAVE_EVENT);
		};
	}, [queryClient]);

	const handleRegisterDevice = useCallback(async (userId: string) => {
		if (IS_DEVELOPMENT || !beamsClient) return;

		try {
			await beamsClient.start();

			const currentDeviceId = await beamsClient.getDeviceId();
			const isRegistered = !!currentDeviceId;

			if (isRegistered) {
				console.info("Push Notifications Device ID", currentDeviceId);
				return;
			}

			await beamsClient.setUserId(userId, {
				fetchToken: async () => {
					const response = await Post("/notifications/register", {
						user_id: userId,
					});
					return response.body.token;
				},
			});

			const deviceId = await beamsClient.getDeviceId();
			console.info("Push Notifications Device ID", deviceId);
		} catch (error) {
			console.error(error);
		}
	}, []);

	return {
		registerDevice: handleRegisterDevice,
	};
};
