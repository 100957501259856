import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export const APIError = ({
	error,
	title = "Error loading information",
}: {
	error: Error;
	title?: string;
}) => {
	return (
		<div className="flex flex-col justify-center items-center h-[600px] text-center px-6">
			<div className="bg-gradient-to-br from-[#383d44] to-[#181b21] rounded-2xl p-8 border border-[#2d3138] max-w-md">
				<FontAwesomeIcon
					icon={faExclamationTriangle}
					className="text-red-500 text-5xl mb-4"
				/>
				<h3 className="text-xl font-bold text-white mb-2">{title}</h3>
				<p className="text-gray-300">
					{error instanceof Error
						? error.message
						: "We have encountered an unexpected error. Please try again later."}
				</p>
			</div>
		</div>
	);
};
