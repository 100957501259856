import type { Game, PartyHistoryEntry, ProfileHistory } from "../../../types";
import { Get } from "../../fetch.wrapper";
import { routes } from "../../routes";

function extractDate(datetimeString: string): string {
	return datetimeString.split("T")[0];
}

function convertEntries(entry: any) {
	return {
		id: entry.party_id,
		date: extractDate(entry.date),
		score: entry.score,
		position: entry.position,

		outcome: entry.outcome,
		game: {
			description: entry.game.description,
			name: entry.game.name,
			icon: entry.game.icon_url,
			gameplayPreview: entry.game.game_url,
			gamePreview: entry.game.preview_url,
			id: entry.game.id,
		} as Game,
	} as PartyHistoryEntry;
}

export default async function GetPartiesHistory() {
	const history = await Get(routes.partiesHistory());

	return {
		parties: history.entries.map(convertEntries),
		totalBuyIns: history.total_buy_ins,
		userId: history.user_id,
		winRatio: Number(Number(history.win_ratio).toFixed(2)),
	} as ProfileHistory;
}
