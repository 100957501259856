import { useInstallPWA } from "src/hooks/useInstall";
import { isIOSDevice } from "src/utils/is-ios-device";
import { GradientBorder } from "./GradientContainer";

export const InstallButton = () => {
	const { install } = useInstallPWA();
	const isIOS = isIOSDevice();

	const isRemoved = process.env.REACT_APP_DOWNLOAD_ENABLED === "false";

	if (isRemoved) {
		return null;
	}

	return (
		<>
			{!isIOS && (
				<GradientBorder padding="p-0.5" rounded="rounded-lg">
					<button
						type="button"
						onClick={() => install()}
						className="px-5 py-2 rounded-lg transition-colors bg-tic-taps-grey text-white hover:bg-gray-900 hover:shadow-glow"
					>
						Download
					</button>
				</GradientBorder>
			)}
		</>
	);
};
