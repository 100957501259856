import {
	PartyParticipantCreateRequest,
	TransactionCurrencyCode,
	TransactionCurrencyType,
} from "src/api/types";
import { Post } from "../../fetch.wrapper";
import { routes } from "../../routes";

export default async function JoinPartyById(
	partyId: string,
	inviteCode: string,
	buyInAmount: number,
	currencyType: TransactionCurrencyType,
	currencyCode: TransactionCurrencyCode,
): Promise<string> {
	const result = await Post<PartyParticipantCreateRequest>(
		routes.joinPartyById(),
		{
			party_id: partyId,
			buy_in_amount: buyInAmount,
			currency_type: currencyType,
			currency_code: currencyCode,
			invite_code: inviteCode,
		},
	);

	return result.party_id;
}
