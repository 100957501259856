import type { PartyParticipantsResponse } from "src/api/types";
import type { Participant } from "../../../types";
import { Get } from "../../fetch.wrapper";
import { routes } from "../../routes";

export default async function GetParticipants(
	partyId: string,
): Promise<Participant[]> {
	const data = await Get<PartyParticipantsResponse>(
		routes.getParticipants(partyId),
	);

	return data?.flat().map(
		(d) =>
			({
				profile: {
					email: d.email,
					gamertag: d.gamertag,
					id: d.user_id,
					profileImage: d.profile_picture,
					username: d.name,
					xp: d.xp,
				},
				// @ts-ignore TODO: See if this is still needed
				buyIn: d.buy_in_amount,
				highSchore: d.score,
				hasPlayed: d.has_played,
			}) as Participant,
	) as Participant[];
}
