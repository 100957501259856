import { CreatePartySettings } from "src/pages/app/Party/CreatePartyPage";
import { Post } from "../fetch.wrapper";
import { routes } from "../routes";
import { PartyCreateRequest } from "../types";

export default async function CreateParty(
	party: CreatePartySettings,
): Promise<string> {
	const result = await Post<PartyCreateRequest>(routes.createParty(), {
		name: party.name,
		is_private: party.isPrivate,
		buy_in_amount: party.buyIn,
		currency_type: party.currency.type,
		currency_code: party.currency.code,
		game_id: party.game.id,
		duration: "PT5M",
		invite_code: party.inviteCode,
	});
	return result.id;
}
