import { routes } from "../../routes";
import { Get } from "../../fetch.wrapper";
import type { Profile } from "../../../types";
import type { UserAccount } from "src/api/types";

export default async function GetUser(id: string): Promise<Profile> {
	const data = await Get<UserAccount>(routes.user(id));
	return {
		id: data.id,
		username: data.nickname ?? "",
		gamertag: data.gamer_tag,
		email: data.email_address ?? "",
		profileImage: data.profile_image,
		xp: 0,
	};
}
