import { useEffect, useState } from "react";

export function useStorageState<T>(
	key: string,
	initialValue: T,
	serialize: (value: T) => string,
	deserialize: (value: string) => T,
): [T | undefined, (value: T | undefined) => void] {
	const [value, setValue] = useState<T | undefined>(undefined);

	useEffect(() => {
		const storedValue = localStorage.getItem(key);

		if (storedValue !== null) {
			try {
				setValue(deserialize(storedValue));
			} catch (err) {
				setValue(undefined);
			}
		} else {
			handleSetValue(initialValue);
		}
	}, [key, initialValue]);

	function handleSetValue(newValue: T | undefined) {
		setValue(newValue);
		if (newValue === undefined) {
			localStorage.removeItem(key);
		} else {
			localStorage.setItem(key, serialize(newValue));
		}
	}

	return [value, handleSetValue];
}
