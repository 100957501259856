const BubblesBackground = () => {
	return (
		<svg
			role="presentation"
			xmlns="http://www.w3.org/2000/svg"
			height="100%"
			width="100%"
			viewBox="75 150 500 1000"
		>
			<defs>
				<linearGradient id="bubblesBackground">
					<stop offset="0%" stopColor="#04b378" />
					<stop offset="50%" stopColor="#4579F5" />
					<stop offset="100%" stopColor="#9C42F5" />
				</linearGradient>
			</defs>
			<g fill="url(#bubblesBackground)">
				<circle cx="561" cy="398" r="152" />
				<circle cx="414" cy="117" r="67" />
				<circle cx="417" cy="823" r="96" />
				<circle cx="75" cy="211" r="74" />
				<circle cx="93" cy="582" r="71" />
			</g>
		</svg>
	);
};

export default BubblesBackground;
