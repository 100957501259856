import { useNavigate } from "react-router-dom";
import { CallToAction } from "../website/CallToAction";
import { Footer } from "../website/Footer";
import { HeaderHero } from "../website/Hero";
import { PrimaryFeatures } from "../website/PrimaryFeatures";
import { useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import IosInstall from "../website/IosInstall";
import { ttRoutes } from "src/hooks/useTTRouter";
import { isIOSDevice } from "src/utils/is-ios-device";

declare global {
	interface Window {
		deferredPrompt: Event;
	}
}
export default function About() {
	const [isOpen, setIsOpen] = useState(true);

	const isIOS = isIOSDevice();
	const navigate = useNavigate();

	useEffect(() => {
		const platform = Capacitor.getPlatform();
		navigate(platform === "web" ? "/about" : ttRoutes.home());
	}, [navigate]);

	window.addEventListener("beforeinstallprompt", (event) => {
		event.preventDefault();
		window.deferredPrompt = event;
	});

	return (
		<div className="bg-tic-taps-grey">
			<HeaderHero />
			{isIOS && isOpen && (
				<div className="fixed bottom-0 left-0 w-full z-50 bg-white shadow-lg">
					<IosInstall setIsOpen={setIsOpen} />
				</div>
			)}
			<PrimaryFeatures />
			<CallToAction />
			<Footer />
		</div>
	);
}
