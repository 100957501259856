import { BellIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

import styles from "./index.module.css";
import type { Notification } from "src/api/types";
import { NotificationsSheet } from "../NotificationsSheet";
import { useNotificationsCount } from "src/hooks/useNotificationsCount";
import { NotificationDetailSheet } from "../NotificationDetailSheet";

export const NotificationsMenuItem = () => {
	const { notificationsCount } = useNotificationsCount();
	const [isOpen, setIsOpen] = useState(false);
	const [selectedNotification, setSelectedNotification] =
		useState<Notification | null>(null);

	return (
		<>
			<button
				type="button"
				aria-label="Notifications"
				onClick={() => setIsOpen(true)}
				aria-current={isOpen}
				className={styles.button}
			>
				<BellIcon className="size-8 transition-all duration-100 ease-in-out" />
				{notificationsCount !== undefined && notificationsCount > 0 && (
					<span className={styles.notificationCount}>{notificationsCount}</span>
				)}
			</button>
			<NotificationsSheet
				isOpen={isOpen && selectedNotification === null}
				onClose={() => setIsOpen(false)}
				onViewNotification={setSelectedNotification}
			/>
			<NotificationDetailSheet
				isOpen={selectedNotification !== null}
				onClose={() => setSelectedNotification(null)}
				notification={selectedNotification}
			/>
		</>
	);
};
